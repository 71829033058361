import { Component, Input } from "@angular/core";
import { IRecurringAppointmentModel, IiCalModel, IDropdownModel } from "@models";
import { LookupService } from "@services";

@Component({
    selector: "recurring-appointment-info",
    templateUrl: "./recurring-appointment-info.component.html",
    styleUrls: ["./recurring-appointment-info.component.scss"],
    providers: [LookupService]
})

export class RecurringAppointmentInfoComponent {
    @Input() model: IRecurringAppointmentModel;

    paymentTerms: IDropdownModel[];
    jobTypes: IDropdownModel[];

    constructor(private readonly lookupService: LookupService) {
        this.paymentTerms = this.lookupService.getPaymentTermsForDropdown();
        this.jobTypes = this.lookupService.getJobTypesForDropdown();
    }

    getPaymentTermById(id: number) {
        const terms = this.paymentTerms.filter((item) => {
            return item.id === id;
        });

        if (terms.length === 1)
            return terms[0].text;
        return "";
    }

    getJobTypeById(id: number) {
        const jobs = this.jobTypes.filter((item) => {
            return item.id === id;
        });

        if (jobs.length === 1)
            return jobs[0].text;
        return "";
    }

    getFrequencyByModel(model: IiCalModel) {
        switch (model.frequency) {
            case "Daily": return "Every Day";
            case "Weekly":
                if (model.dayOfWeek)
                    return `Every ${model.dayOfWeek}`;
                else return `Every ${this.getWeekDaysStringFromProperties(model)}`;
            case "Bi-Weekly":
                return `Every other ${model.dayOfWeek}`;
            case "Monthly":
                switch (model.dateType) {
                    case 0:
                        return this.ordinalSuffixOf(model.dayOfMonth);
                    case 1:
                        return this.ordinalSuffixOf(model.weekNumber) + " " + model.dayOfWeek;
                }
                break;
        }
    }

    getWeekDaysStringFromProperties(model: IiCalModel) {
        const res = new Array<string>();

        if (model.weeklySunday)
            res.push("Sunday");

        if (model.weeklyMonday)
            res.push("Monday");

        if (model.weeklyTuesday)
            res.push("Tuesday");

        if (model.weeklyWednesday)
            res.push("Wednesday");

        if (model.weeklyThursday)
            res.push("Thursday");

        if (model.weeklyFriday)
            res.push("Friday");

        if (model.weeklySaturday)
            res.push("Saturday");

        return this.formatArray(res);
    }

    formatArray(arr: string[]) {
        let outStr = "";
        if (arr.length === 1) {
            outStr = arr[0];
        } else if (arr.length === 2) {

            outStr = arr.join(" and ");
        } else if (arr.length > 2) {

            outStr = arr.slice(0, -1).join(", ") + ", and " + arr.slice(-1);
        }
        return outStr;
    }

    ordinalSuffixOf(i: number) {
        const j = i % 10;
        const k = i % 100;
        if (j === 1 && k !== 11) {
            return i + "st";
        }
        if (j === 2 && k !== 12) {
            return i + "nd";
        }
        if (j === 3 && k !== 13) {
            return i + "rd";
        }
        return i + "th";
    }
}