import { Component, Input, AfterViewInit, ViewChild, ElementRef, HostListener } from "@angular/core";

@Component({
	selector: "slick-context-menu",
	templateUrl: "slick-context-menu.component.html"
})
export class SlickContextMenuComponent implements AfterViewInit {
	@ViewChild("slickContextMenuRef") slickContextMenuRef: ElementRef;
	@ViewChild("slickContextMenuDropdownMenuRef") slickContextMenuDropdownMenuRef: ElementRef;
	
	@HostListener('document:click', ['$event.target'])
	public onClick(targetElement) {
		(<HTMLDivElement>this.slickContextMenuRef.nativeElement).style.display = "none";
		(<HTMLDivElement>this.slickContextMenuDropdownMenuRef.nativeElement).style.opacity = "0";

    }

    @HostListener('document:mousedown', ['$event'])
    public onRightClick(event: MouseEvent) {
        if (event.button === 2) {
            this.onClick(event.target);
        }
    }

	constructor() {
	}

	ngAfterViewInit() {
		// Remove this from the inline version and put it on the body so that it doesn't hide behind the bottom
		// of a div when it expands beyond the bottom.
		this.slickContextMenuRef.nativeElement.remove(this.slickContextMenuRef);
		document.body.appendChild(this.slickContextMenuRef.nativeElement);
	}

	showContextMenu(e: MouseEvent) {
		(<HTMLDivElement>this.slickContextMenuRef.nativeElement).style.left = (e.pageX) + "px";
		(<HTMLDivElement>this.slickContextMenuRef.nativeElement).style.top = (e.pageY) + "px";
		(<HTMLDivElement>this.slickContextMenuRef.nativeElement).style.display = "block";
		setTimeout(() => {
			(<HTMLDivElement>this.slickContextMenuDropdownMenuRef.nativeElement).style.opacity = "1";
		});
	}
}