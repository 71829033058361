import { Component, Input, Output, EventEmitter, AfterViewInit, OnDestroy, OnChanges, SimpleChanges, ViewChild, ElementRef } from "@angular/core";
import { ISlickSliderTickModel } from "./slick-slider-tick.model";
import { SlickSleepService } from "../utils/slick-sleep.service";

@Component({
	selector: "slick-slider",
	templateUrl: "slick-slider.component.html"
})
export class SlickSliderComponent implements AfterViewInit, OnChanges, OnDestroy {
	@Input() ticks: ISlickSliderTickModel[] = [];
	@Input() startTick: number;
	@Input() endTick: number;
	@Output() startTickChange = new EventEmitter<number>();
	@Output() endTickChange = new EventEmitter<number>();

	@ViewChild("sliderTicksRef") sliderTicksRef: ElementRef;

	fnDocumentMouseUp = (e) => this.documentMouseUp(e);

	startMoving = false;
	endMoving = false;
	startLeftPx: string;
	endLeftPx: string;
	selectionLeftPx: string;
	selectionWidthPx: string;

	async ngAfterViewInit() {
		this.recalc();
	}

	ngOnDestroy() {
		document.removeEventListener("mouseup", this.fnDocumentMouseUp, true);
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.startTick) 
			this.startTick = parseInt(this.startTick.toString());
		
		if (changes.endTick)
			this.endTick = parseInt(this.endTick.toString());
	}

	onStartMouseDown(e: MouseEvent) {
		this.startMoving = true;
		this.endMoving = false;
		document.removeEventListener("mouseup", this.fnDocumentMouseUp, true);
		document.addEventListener("mouseup", this.fnDocumentMouseUp, true);
	}

	onEndMouseDown(e: MouseEvent) {
		this.startMoving = false;
		this.endMoving = true;
		document.removeEventListener("mouseup", this.fnDocumentMouseUp, true);
		document.addEventListener("mouseup", this.fnDocumentMouseUp, true);
	}

	documentMouseUp(e: MouseEvent) {
		document.removeEventListener("mouseup", this.fnDocumentMouseUp, true);

		if (this.startMoving && this.startTickChange)
			this.startTickChange.emit(this.startTick);

		if (this.endMoving && this.endTickChange)
			this.endTickChange.emit(this.endTick);

		this.startMoving = false;
		this.endMoving = false;
	}

	onTickMouseOver(idx) {
		
		if (this.startMoving === true && idx < this.endTick) {
			this.startTick = idx;
			this.recalc();
		}

		if (this.endMoving === true && idx > this.startTick) {
			this.endTick = idx;
			this.recalc();
		}
	}

	private async recalc() {
		await SlickSleepService.sleep();

		const startLeft = this.findTickLeft(this.startTick) + 3;
		this.startLeftPx = startLeft + "px";

		const endLeft = this.findTickLeft(this.endTick) + 3;
		this.endLeftPx = endLeft + "px";

		this.selectionLeftPx = (startLeft + 7) + "px";
		this.selectionWidthPx = (endLeft - startLeft + 1) + "px";
	}

	private findTickLeft(tickNumber) {
		const tickEl = <HTMLTableCellElement>(<HTMLTableElement>this.sliderTicksRef.nativeElement).querySelector("tr td[data-tick='" + tickNumber + "']");
		return tickEl.offsetLeft;

	}
}