export interface IGetPaymentsListModel {
    searchString: string;
    pageSize: number;
    showUnappliedOnly: boolean;
    customerId: number;
}

export class GetPaymentsListModel implements IGetPaymentsListModel {
    searchString: string;
    pageSize: number;
    showUnappliedOnly: boolean;
    customerId: number;
}