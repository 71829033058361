// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.job-costing {
  width: 70%;
  min-width: 700px;
  margin: 0; /* Center the table */
  padding: 0;
  color: #333;
  background-color: #fff;
  border-collapse: separate; /* Use separate to prevent border doubling */
  border-spacing: 0;
  border-radius: 8px; /* Rounded corners */
  overflow: hidden; /* Ensures the border-radius is applied */
  border: 1px solid #dee2e6; /* Single border around the entire table */
}

.job-costing th,
.job-costing td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: none;
}

.job-costing tr:first-child th {
  border-top: none;
}

.job-costing th {
  background-color: #f8f8f8;
  font-weight: 600;
}

.right-align {
  text-align: right !important;
}

.job-costing tr.total-row td,
.job-costing tr.net-income-row td {
  font-weight: 600;
  color: #000;
}

.job-costing .value {
  font-weight: 500;
}

.bold-text {
  font-weight: bold;
}

.bg-primary-subtle {
  background-color: rgba(0, 123, 255, 0.1);
}`, "",{"version":3,"sources":["webpack://./app-desktop/src/app/job-costing-details/job-costing-details.component.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,gBAAA;EACA,SAAA,EAAA,qBAAA;EACA,UAAA;EACA,WAAA;EACA,sBAAA;EACA,yBAAA,EAAA,4CAAA;EACA,iBAAA;EACA,kBAAA,EAAA,oBAAA;EACA,gBAAA,EAAA,yCAAA;EACA,yBAAA,EAAA,0CAAA;AACJ;;AAEA;;EAEI,kBAAA;EACA,gBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAGA;EACI,yBAAA;EACA,gBAAA;AAAJ;;AAGA;EACI,4BAAA;AAAJ;;AAGA;;EAEI,gBAAA;EACA,WAAA;AAAJ;;AAGA;EACI,gBAAA;AAAJ;;AAGA;EACI,iBAAA;AAAJ;;AAGA;EACI,wCAAA;AAAJ","sourcesContent":[".job-costing {\r\n    width: 70%;\r\n    min-width: 700px;\r\n    margin: 0; /* Center the table */\r\n    padding: 0;\r\n    color: #333;\r\n    background-color: #fff;\r\n    border-collapse: separate; /* Use separate to prevent border doubling */\r\n    border-spacing: 0;\r\n    border-radius: 8px; /* Rounded corners */\r\n    overflow: hidden; /* Ensures the border-radius is applied */\r\n    border: 1px solid #dee2e6; /* Single border around the entire table */\r\n}\r\n\r\n.job-costing th,\r\n.job-costing td {\r\n    padding: 12px 15px;\r\n    text-align: left;\r\n    border-bottom: none;\r\n}\r\n\r\n.job-costing tr:first-child th {\r\n    border-top: none;\r\n}\r\n\r\n\r\n.job-costing th {\r\n    background-color: #f8f8f8;\r\n    font-weight: 600;\r\n}\r\n\r\n.right-align {\r\n    text-align: right !important;\r\n}\r\n\r\n.job-costing tr.total-row td,\r\n.job-costing tr.net-income-row td {\r\n    font-weight: 600;\r\n    color: #000;\r\n}\r\n\r\n.job-costing .value {\r\n    font-weight: 500;\r\n}\r\n\r\n.bold-text {\r\n    font-weight: bold;\r\n}\r\n\r\n.bg-primary-subtle {\r\n    background-color: rgba(0, 123, 255, 0.1);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
