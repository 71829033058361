// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-name {
  position: relative;
}

.user-name .valid-icons {
  position: absolute;
  right: 5px;
  top: 5px;
}

.user-name .loading {
  position: absolute;
  right: 5px;
  top: 10px;
}`, "",{"version":3,"sources":["webpack://./app-desktop/src/components/user-name/user-name.component.scss"],"names":[],"mappings":"AAAA;EACC,kBAAA;AACD;;AAEA;EACC,kBAAA;EACA,UAAA;EACA,QAAA;AACD;;AAEA;EACC,kBAAA;EACA,UAAA;EACA,SAAA;AACD","sourcesContent":[".user-name {\r\n\tposition: relative;\r\n}\r\n\r\n.user-name .valid-icons {\r\n\tposition: absolute;\r\n\tright: 5px;\r\n\ttop: 5px;\r\n}\r\n\r\n.user-name .loading {\r\n\tposition: absolute;\r\n\tright: 5px;\r\n\ttop: 10px\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
