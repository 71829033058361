// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input[type=checkbox] {
  margin-right: initial !important;
  position: initial !important;
  top: initial !important;
}`, "",{"version":3,"sources":["webpack://./app-desktop/src/app/appointments/components/checklist-dialog/checklist-edit/checklist-edit.component.scss"],"names":[],"mappings":"AAAA;EACI,gCAAA;EACA,4BAAA;EACA,uBAAA;AACJ","sourcesContent":["input[type=checkbox] {\r\n    margin-right: initial !important;\r\n    position: initial !important;\r\n    top: initial !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
