export interface IAppointmentStatusUpdate {
    appointmentId: number;
    appointmentStatusDescription: string;
    notes: string;
    customerNotes: string;
}

export class AppointmentStatusUpdate {
    appointmentId: number;
    appointmentStatusDescription: string;
    notes: string;
    customerNotes: string;
}