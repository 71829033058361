export interface ICustomerOutreachListViewModel {
    customerId: number;
    lastOutreachDate?: Date;
	nextOutreachDate?: Date;
    outreachType: string;
    notes: string;
    companyName: string;  
    fullName: string;
	userFullName: string; 
	customerAreaName: string;  
}

export class CustomerOutreachListViewModel implements ICustomerOutreachListViewModel {
    customerId: number;
    lastOutreachDate?: Date;
	nextOutreachDate?: Date;
    outreachType: string;
    notes: string;
    companyName: string;  
    fullName: string;
	userFullName: string; 
	customerAreaName: string;  
}
