import { ICustomerModel } from "../customers/customer.model";

export interface ISkeditPayTransactionLogModel {
    paymentId: number;
    referenceNumber: string;
    creditCardInfo: string;
    paymentDate: Date;
    paymentDateDisplay: string;
    paymentType: string;
    amount: number;
    amountDisplay: string;
    notes: string;
    firstName: string;
    lastName: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipcode: string;
    country: string;
    isVoided: boolean;
}

export class SkeditPayTransactionLogModel implements ISkeditPayTransactionLogModel {
    paymentId: number;
    referenceNumber: string;
    creditCardInfo: string;
    paymentDate: Date;
    paymentDateDisplay: string;
    paymentType: string;
    amount: number;
    amountDisplay: string;
    notes: string;
    firstName: string;
    lastName: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipcode: string;
    country: string;
    isVoided: boolean;
}