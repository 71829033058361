import { Injectable } from "@angular/core";
import { IMaintenanceSummaryModel, IMaintenanceInventoryModel, IMaintenanceReadingModel} from "@models";
import { HttpService } from "./utils/http.service";

@Injectable()
export class MaintenanceHistoryService {
	constructor(private readonly httpService: HttpService) { }

	async getMaintenanceReading(maintenanceReadingId: number): Promise<IMaintenanceReadingModel> {
		return await this.httpService.get('/maintenanceHistory/getMaintenanceReading', { maintenanceReadingId });
	}

	async addMaintenanceReading(maintenanceReadingModel: IMaintenanceReadingModel): Promise<IMaintenanceReadingModel> {
		return await this.httpService.post('/maintenanceHistory/addMaintenanceReading', maintenanceReadingModel);
	}

	async updateMaintenanceReading(maintenanceReadingModel: IMaintenanceReadingModel): Promise<IMaintenanceReadingModel> {
		return await this.httpService.post('/maintenanceHistory/updateMaintenanceReading', maintenanceReadingModel)
	}

	async getMaintenanceSummary(customerId: number): Promise<IMaintenanceSummaryModel> {
		return await this.httpService.get('/maintenanceHistory/getMaintenanceSummary', { customerId });
	}

	async addMaintenanceInventory(maintenanceInventoryModel: IMaintenanceInventoryModel): Promise<IMaintenanceInventoryModel> {
		return await this.httpService.post('/maintenanceHistory/addMaintenanceInventory', maintenanceInventoryModel);
	}

	async updateMaintenanceInventory(maintenanceInventoryModel: IMaintenanceInventoryModel): Promise<IMaintenanceInventoryModel> {
		return await this.httpService.post('/maintenanceHistory/updateMaintenanceInventory', maintenanceInventoryModel)
	}

	async deleteMaintenanceInventory(maintenanceInventoryId: number): Promise<boolean> {
		return await this.httpService.delete('/maintenanceHistory/deleteMaintenanceInventory', { maintenanceInventoryId })
	}
}
