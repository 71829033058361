import { Component, Input, ViewChild, ElementRef, OnChanges } from "@angular/core";
import { SlickSleepService } from "../utils/slick-sleep.service";
import { SlickUtilsService } from "../utils/slick-utils.service";

@Component({
	selector: "slick-accordion-item",
	templateUrl: "slick-accordion-item.component.html"
})
export class SlickAccordionItemComponent implements OnChanges {
	@Input("header") header: string;
	@Input("headerIcon") headerIcon: string;
	@Input("key") key: string;
	@Input() noExpand: boolean = false;

	@ViewChild("slickAccordionItemRef") slickAccordionItemRef: ElementRef;
	@ViewChild("slickAccordionBodyWrapperRef") slickAccordionBodyWrapperRef: ElementRef;
	@ViewChild("slickAccordionBodyRef") slickAccordionBodyRef: ElementRef;
	@ViewChild("slickAccordionBouncePaddingRef") slickAccordionBouncePaddingRef: ElementRef;

	readonly PIXELS_PER_TICK = 15;

	isExpanded: boolean = false;
	index: number;
	// This will be set in the slick-accordion.component as a callback
	onChangeCallback: any;

	constructor() { }

	ngOnChanges() {
		this.noExpand = (this.noExpand.toString().toLowerCase() === 'true') ? true : false;
	}

	async onHeaderClicked() {
		if (this.noExpand) {
			if (this.onChangeCallback)
				this.onChangeCallback(this.index);
		}
		else {
			if (this.isExpanded === false)
				this.expand();
			else
				this.collapse();
		}

	}

	async expand() {
		if (this.noExpand)
			return;

		if (this.isExpanded === true)
			return;

		if (this.onChangeCallback)
			this.onChangeCallback(this.index);

		this.isExpanded = true;

		await SlickUtilsService.waitForElementRef(this.slickAccordionItemRef);

		// Give the collapses a chance to collapse down before we expand
		await SlickSleepService.sleep(100);
		const maxHeight = (<HTMLDivElement>this.slickAccordionBodyRef.nativeElement).offsetHeight;
		(<HTMLDivElement>this.slickAccordionBouncePaddingRef.nativeElement).style.display = "block";
		(<HTMLDivElement>this.slickAccordionBodyWrapperRef.nativeElement).style.maxHeight = (maxHeight + 30) + "px";
		await SlickSleepService.sleep(125);
		(<HTMLDivElement>this.slickAccordionBodyWrapperRef.nativeElement).style.maxHeight = maxHeight + "px";
		await SlickSleepService.sleep(100);
		(<HTMLDivElement>this.slickAccordionBouncePaddingRef.nativeElement).style.display = "none";
		(<HTMLDivElement>this.slickAccordionBodyWrapperRef.nativeElement).style.maxHeight = "none";
	}

	async collapse(ignoreOnChangeCallback: boolean = false) {
		if (this.isExpanded === false)
			return;

		this.isExpanded = false;

		// The animations requires a number for maxHeight.  Just set it to the current height, then to 0
		const maxHeight = (<HTMLDivElement>this.slickAccordionBodyRef.nativeElement).offsetHeight;
		(<HTMLDivElement>this.slickAccordionBodyWrapperRef.nativeElement).style.maxHeight = maxHeight + "px";
		await SlickSleepService.sleep();
		(<HTMLDivElement>this.slickAccordionBodyWrapperRef.nativeElement).style.maxHeight = "0";

		if (this.onChangeCallback && ignoreOnChangeCallback === false)
			this.onChangeCallback(this.index);
	}

	getKey(): string {
		return this.key || null;
	}
}