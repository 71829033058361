// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.legacy-chat {
  position: absolute;
  right: 40px;
  bottom: 2px;
  z-index: 150000;
}

.legacy-chat .legacy-chat-group-list .legacy-chat-group-list-expanded {
  max-height: 400px;
  min-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
  transition: 0.4s;
}

.legacy-chat .legacy-chat-group-list .legacy-chat-group-list-collapsed {
  min-height: 0;
  max-height: 0;
  overflow: hidden;
  transition: 0.4s;
}

.legacy-chat-group {
  position: absolute;
  bottom: 2px;
  z-index: 150000;
}

.legacy-chat-group .legacy-chat-group-expanded {
  height: 400px;
  max-height: 400px;
  min-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
  transition: 0.4s;
}

.legacy-chat-group .legacy-chat-group-collapsed {
  height: 0;
  min-height: 0;
  max-height: 0;
  overflow: hidden;
  transition: 0.4s;
}`, "",{"version":3,"sources":["webpack://./app-desktop/src/app/chat/legacy-chat/legacy-chat.component.scss"],"names":[],"mappings":"AACA;EACC,kBAAA;EACA,WAAA;EACA,WAAA;EACA,eAAA;AAAD;;AAGC;EACC,iBAAA;EACA,iBAAA;EACA,kBAAA;EACA,gBAAA;EACA,gBAAA;AAAF;;AAGC;EACC,aAAA;EACA,aAAA;EACA,gBAAA;EACA,gBAAA;AAAF;;AAGA;EACC,kBAAA;EACA,WAAA;EACA,eAAA;AAAD;;AAGC;EACC,aAAA;EACA,iBAAA;EACA,iBAAA;EACA,kBAAA;EACA,gBAAA;EACA,gBAAA;AAAF;;AAGC;EACC,SAAA;EACA,aAAA;EACA,aAAA;EACA,gBAAA;EACA,gBAAA;AAAF","sourcesContent":["\r\n.legacy-chat {\r\n\tposition: absolute;\r\n\tright: 40px;\r\n\tbottom: 2px;\r\n\tz-index: 150000;\r\n}\r\n\r\n\t.legacy-chat .legacy-chat-group-list .legacy-chat-group-list-expanded {\r\n\t\tmax-height: 400px;\r\n\t\tmin-height: 400px;\r\n\t\toverflow-x: hidden;\r\n\t\toverflow-y: auto;\r\n\t\ttransition: .4s;\r\n\t}\r\n\r\n\t.legacy-chat .legacy-chat-group-list .legacy-chat-group-list-collapsed {\r\n\t\tmin-height: 0;\r\n\t\tmax-height: 0;\r\n\t\toverflow: hidden;\r\n\t\ttransition: .4s;\r\n\t}\r\n\r\n.legacy-chat-group {\r\n\tposition: absolute;\r\n\tbottom: 2px;\r\n\tz-index: 150000;\r\n}\r\n\r\n\t.legacy-chat-group .legacy-chat-group-expanded {\r\n\t\theight: 400px;\r\n\t\tmax-height: 400px;\r\n\t\tmin-height: 400px;\r\n\t\toverflow-x: hidden;\r\n\t\toverflow-y: auto;\r\n\t\ttransition: .4s;\r\n\t}\r\n\r\n\t.legacy-chat-group .legacy-chat-group-collapsed {\r\n\t\theight: 0;\r\n\t\tmin-height: 0;\r\n\t\tmax-height: 0;\r\n\t\toverflow: hidden;\r\n\t\ttransition: .4s;\r\n\t}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
