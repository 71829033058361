export interface ISimpleHourModel {
    companyId: number;
    officeOpenHours: string;
    officeCloseHours: string;
    serviceOpenHours: string;
    serviceCloseHours: string;
    officeOpenSaturday: boolean;
    officeOpenSunday: boolean;
    serviceOpenSaturday: boolean;
    serviceOpenSunday: boolean;
}

export class SimpleHourModel implements ISimpleHourModel {
    constructor() { }

    companyId: number;
    officeOpenHours: string;
    officeCloseHours: string;
    serviceOpenHours: string;
    serviceCloseHours: string;
    officeOpenSaturday: boolean;
    officeOpenSunday: boolean;
    serviceOpenSaturday: boolean;
    serviceOpenSunday: boolean;
}