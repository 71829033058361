// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quick-add-container {
	width: 100px;
	min-height: 100px;
}

.quick-add-img {
	width: 100px;
	min-width: 100px;
	min-height: 100px;
}

	.quick-add-img img {
		height: 100px;
		width: 100px;
		border: 2px solid var(--bs-gray-300);
	}

	.quick-add-text {
		text-align: center;
		font-size: .8rem;
	}`, "",{"version":3,"sources":["webpack://./app-desktop/src/components/quick-adds/quick-adds.component.css"],"names":[],"mappings":"AAAA;CACC,YAAY;CACZ,iBAAiB;AAClB;;AAEA;CACC,YAAY;CACZ,gBAAgB;CAChB,iBAAiB;AAClB;;CAEC;EACC,aAAa;EACb,YAAY;EACZ,oCAAoC;CACrC;;CAEA;EACC,kBAAkB;EAClB,gBAAgB;CACjB","sourcesContent":[".quick-add-container {\r\n\twidth: 100px;\r\n\tmin-height: 100px;\r\n}\r\n\r\n.quick-add-img {\r\n\twidth: 100px;\r\n\tmin-width: 100px;\r\n\tmin-height: 100px;\r\n}\r\n\r\n\t.quick-add-img img {\r\n\t\theight: 100px;\r\n\t\twidth: 100px;\r\n\t\tborder: 2px solid var(--bs-gray-300);\r\n\t}\r\n\r\n\t.quick-add-text {\r\n\t\ttext-align: center;\r\n\t\tfont-size: .8rem;\r\n\t}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
