// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
 
.container {
    padding: 10px;
}

.address-info {
    padding-bottom: 20px;
}

.address-card-ui {
    min-height: 100px;
    position: relative;
    margin: 0 auto;
    width: 630px;
    min-width: 400px;
    outline: none;
    display: inline-block;
}

    .address-card-ui:hover, .address-card-ui.add-address-holder:hover {
        cursor: pointer;
    }

        .address-card-ui .add-address {
            line-height: 118px;
            text-align: center;
        }

        .address-card-ui .address-description {
            line-height: 70px;
            letter-spacing: 2px;
            font-weight: bold;
        }

        .address-card-ui > div {
            line-height: 20px;
        }
label{
    font-weight: 500;
    padding-top: 4px;
}


`, "",{"version":3,"sources":["webpack://./app-desktop/src/app/customers/customer-components/customer-edit/customer-multiple-addresses/customer-multiple-addresses.component.css"],"names":[],"mappings":";;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,cAAc;IACd,YAAY;IACZ,gBAAgB;IAChB,aAAa;IACb,qBAAqB;AACzB;;IAEI;QACI,eAAe;IACnB;;QAEI;YACI,kBAAkB;YAClB,kBAAkB;QACtB;;QAEA;YACI,iBAAiB;YACjB,mBAAmB;YACnB,iBAAiB;QACrB;;QAEA;YACI,iBAAiB;QACrB;AACR;IACI,gBAAgB;IAChB,gBAAgB;AACpB","sourcesContent":["\r\n \r\n.container {\r\n    padding: 10px;\r\n}\r\n\r\n.address-info {\r\n    padding-bottom: 20px;\r\n}\r\n\r\n.address-card-ui {\r\n    min-height: 100px;\r\n    position: relative;\r\n    margin: 0 auto;\r\n    width: 630px;\r\n    min-width: 400px;\r\n    outline: none;\r\n    display: inline-block;\r\n}\r\n\r\n    .address-card-ui:hover, .address-card-ui.add-address-holder:hover {\r\n        cursor: pointer;\r\n    }\r\n\r\n        .address-card-ui .add-address {\r\n            line-height: 118px;\r\n            text-align: center;\r\n        }\r\n\r\n        .address-card-ui .address-description {\r\n            line-height: 70px;\r\n            letter-spacing: 2px;\r\n            font-weight: bold;\r\n        }\r\n\r\n        .address-card-ui > div {\r\n            line-height: 20px;\r\n        }\r\nlabel{\r\n    font-weight: 500;\r\n    padding-top: 4px;\r\n}\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
