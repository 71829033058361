// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg-container {
  min-height: calc(100vh - 79px);
  text-align: center;
}

.background {
  background-image: url("/content/images/404.png");
  background-repeat: no-repeat;
}`, "",{"version":3,"sources":["webpack://./app-desktop/src/app/page-not-found/page-not-found.component.scss"],"names":[],"mappings":"AAAA;EACC,8BAAA;EACA,kBAAA;AACD;;AAEA;EACC,gDAAA;EACA,4BAAA;AACD","sourcesContent":[".bg-container {\r\n\tmin-height: calc(100vh - 79px);\r\n\ttext-align: center;\r\n}\r\n\r\n.background {\r\n\tbackground-image: url(\"/content/images/404.png\");\r\n\tbackground-repeat: no-repeat;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
