// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.condensed-button {
	width: 34px;
	padding-left: 0;
	padding-right: 0;
}

input {
	line-height: 20px;
	height: 22px;
	float:right;
	width:150px;
	
}

.location-list-item {
	line-height: 1.1em;
	font-size: 12px;
	list-style: none;
	padding-top: 5px;
	padding-bottom: 5px;
	padding-left: 5px;
}



.location-list-item li.bigger {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 3px;
}




.section-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
}

.show-more-link {
	text-align: center;
	margin-top: 10px;
}

	.show-more-link a {
		color: blue;
		
		cursor: pointer;
	}



`, "",{"version":3,"sources":["webpack://./libraries/shared-components/inventory-locations-search/inventory-locations-search.css"],"names":[],"mappings":"AAAA;CACC,WAAW;CACX,eAAe;CACf,gBAAgB;AACjB;;AAEA;CACC,iBAAiB;CACjB,YAAY;CACZ,WAAW;CACX,WAAW;;AAEZ;;AAEA;CACC,kBAAkB;CAClB,eAAe;CACf,gBAAgB;CAChB,gBAAgB;CAChB,mBAAmB;CACnB,iBAAiB;AAClB;;;;AAIA;IACI,iBAAiB;IACjB,eAAe;IACf,kBAAkB;AACtB;;;;;AAKA;IACI,eAAe;IACf,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;CACC,kBAAkB;CAClB,gBAAgB;AACjB;;CAEC;EACC,WAAW;;EAEX,eAAe;CAChB","sourcesContent":[".condensed-button {\r\n\twidth: 34px;\r\n\tpadding-left: 0;\r\n\tpadding-right: 0;\r\n}\r\n\r\ninput {\r\n\tline-height: 20px;\r\n\theight: 22px;\r\n\tfloat:right;\r\n\twidth:150px;\r\n\t\r\n}\r\n\r\n.location-list-item {\r\n\tline-height: 1.1em;\r\n\tfont-size: 12px;\r\n\tlist-style: none;\r\n\tpadding-top: 5px;\r\n\tpadding-bottom: 5px;\r\n\tpadding-left: 5px;\r\n}\r\n\r\n\r\n\r\n.location-list-item li.bigger {\r\n    font-weight: bold;\r\n    font-size: 14px;\r\n    margin-bottom: 3px;\r\n}\r\n\r\n\r\n\r\n\r\n.section-title {\r\n    font-size: 16px;\r\n    font-weight: bold;\r\n    margin-bottom: 5px;\r\n}\r\n\r\n.show-more-link {\r\n\ttext-align: center;\r\n\tmargin-top: 10px;\r\n}\r\n\r\n\t.show-more-link a {\r\n\t\tcolor: blue;\r\n\t\t\r\n\t\tcursor: pointer;\r\n\t}\r\n\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
