import { IInvoiceModel } from "./invoice.model";

export interface IBatchAcceptInvoiceModel {
    mergedInvoiceFileName: string;
    mergedInvoiceFileUrl: string;
    invoiceIds: number[];
    hashList: string[];
    invoiceIdErrorList: number[];
    errorMessage: string;
    InvoiceErrors: string[];
    companyId: number;
    ipAddress: string;
}
	
export class BatchAcceptInvoiceModel implements IBatchAcceptInvoiceModel {
    mergedInvoiceFileName: string;
    mergedInvoiceFileUrl: string;
    invoiceIds: number[];
    hashList: string[];
    invoiceIdErrorList: number[];
    errorMessage: string;
    InvoiceErrors: string[];
    companyId: number;
    ipAddress: string;
}

	