import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import * as moment from 'moment';
import { IDailyPreChecklistModel } from "@models";
import { GlobalsService } from "./utils/globals.service";

@Injectable()
export class DailyPreChecklistService {
	constructor(private readonly httpService: HttpService) {
	}

	async getDailyPreChecklistForUser(userId: number, scheduledDate: Date): Promise<IDailyPreChecklistModel> {
		return new Promise<IDailyPreChecklistModel>(async (res) => {
			if (GlobalsService.company.useInventory === false) {
				res(null);
			}
			else {

				const params = {
					userId: userId,
					scheduledDate: moment(scheduledDate).format("MM/DD/yyyy")
				}

				res(this.httpService.get(`dailyPreChecklist/getDailyPreChecklistForUser`, params));
			}
		});
	}

	async generateDailyPreChecklist(resourceId: number, scheduledDate: Date): Promise<void> {
		const params = {
			resourceId: resourceId,
			scheduledDate: moment(scheduledDate).format("MM/DD/yyyy")
		}

		return this.httpService.get(`dailyPreChecklist/generateDailyPreChecklist`, params);
	}

	async toggleChecked(dailyPreChecklistLineItemId: number, checked: boolean): Promise<void> {
		return this.httpService.patch(`dailyPreChecklist/toggleChecked?dailyPreChecklistLineItemId=${dailyPreChecklistLineItemId}&isChecked=${checked}`);
	}
}
