export interface IUserLookupModel {
    userId: number;
    fullName: string;
    userName: string;
    defaultLocationId: number;
    profilePictureUrl: string;
    canAccessTextChat: boolean;
    active: boolean;
    isCrewMember: boolean;
    isSalesRep: boolean;
    isAR: boolean;
    showOnChat: boolean;
}

export class UserLookupModel implements IUserLookupModel {
    userId: number;
    fullName: string;
    userName: string;
    defaultLocationId: number;
    profilePictureUrl: string;
    canAccessTextChat: boolean;
    active: boolean;
    isCrewMember: boolean;
    isSalesRep: boolean;
    isAR: boolean;
    showOnChat: boolean;
}