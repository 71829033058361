import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, SimpleChange, ViewChild, ElementRef } from "@angular/core";
import { GlobalsService, HttpService, SleepService } from "@services";
import { IItemModel } from "@models";

@
	Component({
		selector: "item-search",
		templateUrl: "item-search.component.html",
		styleUrls: ["item-search.component.css"]
	})

export class ItemSearchComponent implements OnChanges {
	@Input() sku: string;
	@Input() condensed: boolean = false;
	@Input() disabled: boolean = false;
	@Input() showSearchIcon: boolean = true;
	@Input() placeholder = "SKU";

	@Output() onChange: EventEmitter<any> = new EventEmitter();

	@ViewChild("autoCompleteInputRef", { static: true }) autoCompleteInputRef: ElementRef;

	searchText: string;
	results: any[];

	constructor(private httpService: HttpService) {

	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.sku) 
			this.searchText = changes.sku.currentValue;		

		if (changes.condensed)
			this.condensed = (this.condensed.toString().toLowerCase() === "true") ? true : false;

		if (changes.disabled)
			this.disabled = (this.disabled.toString().toLowerCase() === "false") ? false : true;
	}

	async search(searchText: string) {
		// Quick adds always show latham items
		const params = {
			showLathamItemsInItemSearch: true,
			searchText
		}
		this.results = await this.httpService.get(`/items/getItemsForItemSearch`, params);
	}

	onSelect(searchItem: IItemModel) {
		if (!searchItem)
			return;

		if (this.onChange) {
			if (searchItem)
				this.onChange.emit(searchItem);
			else
				this.onChange.emit(null);
		}

        this.results = null;
	}

	onFreeformTextSelect(text: string) {
		if (text === this.searchText)
			return;
		
		if (text) {
			if (this.onChange)
				this.onChange.emit(text);
		}

		this.searchText = null;
		this.results = null;
	}
}
