import {EventEmitter, Injectable} from '@angular/core';
import { HttpService } from './utils/http.service';

@Injectable()
export class SmartlistService {

	constructor(private readonly httpService: HttpService) { }

	async getSmartlistQuestionsByAppointmentTypeId(appointmentTypeId) {
		return await this.httpService.get("/smartlist/getSmartlistQuestionsByAppointmentTypeId", appointmentTypeId)
	}
}
