// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item {
  margin-top: 20px;
  border-top: 1px solid var(--bs-gray-300);
}`, "",{"version":3,"sources":["webpack://./app-desktop/src/app/appointments/components/marks-dialog/marks-edit/marks-edit.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,wCAAA;AACJ","sourcesContent":[".item {\r\n    margin-top: 20px;\r\n    border-top: 1px solid var(--bs-gray-300);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
