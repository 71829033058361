import { IMaintenanceReadingItemValueModel } from "./maintenance-reading-item-value.model"

export interface IMaintenanceReadingItemModel {
	maintenanceReadingItemId: number;
	itemName: string;
	itemValues: IMaintenanceReadingItemValueModel[];
}

export class MaintenanceReadingItemModel implements IMaintenanceReadingItemModel {
	maintenanceReadingItemId: number;
	itemName: string;
	itemValues: IMaintenanceReadingItemValueModel[];
}