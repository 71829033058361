import { Component, Input, Output, EventEmitter } from "@angular/core";
import { IiCalModel, IDropdownModel, DropdownModel } from "@models";

@Component({
    selector: "frequency-edit",
    templateUrl: "./frequency-edit.component.html",
    styleUrls: ["./frequency-edit.component.scss"]
})

export class FrequencyEditComponent {
    @Input() model: IiCalModel;
    @Output() modelChange = new EventEmitter<IiCalModel>();

    @Input() label: string = "";
    @Input() renderWeekAsCheckboxes: boolean = false;
    @Input() workBillingLabel: string = "";
    @Input() disabled: boolean = false;

    frequencyItems = new Array<IDropdownModel>();
    weekDaysItems = new Array<IDropdownModel>();

    weekSequenceList = new Array<IDropdownModel>();
    monthSequenceList = new Array<IDropdownModel>();

    constructor() {
        this.frequencyItems = this.getFrequencyItems();
        this.weekDaysItems = this.getWeekDaysItems();
        this.weekSequenceList = this.getSequenceRange(1, 4);
        this.monthSequenceList = this.getSequenceRange(1, 28);
    }

    getFrequencyItems(): IDropdownModel[] {
        const result = new Array<IDropdownModel>();
        result.push(new DropdownModel("Daily", "Daily"));
        result.push(new DropdownModel("Weekly", "Weekly"));
        result.push(new DropdownModel("Bi-Weekly", "Bi-Weekly"));
        result.push(new DropdownModel("Monthly", "Monthly"));
        return result;
    }

    getWeekDaysItems(): IDropdownModel[] {
        const result = new Array<IDropdownModel>();
        result.push(new DropdownModel("Sunday", "Sunday"));
        result.push(new DropdownModel("Monday", "Monday"));
        result.push(new DropdownModel("Tuesday", "Tuesday"));
        result.push(new DropdownModel("Wednesday", "Wednesday"));
        result.push(new DropdownModel("Thursday", "Thursday"));
        result.push(new DropdownModel("Friday", "Friday"));
        result.push(new DropdownModel("Saturday", "Saturday"));
        return result;
    }

    getSequenceRange(start: number, end) {
        const result = new Array<IDropdownModel>();

        for (let i = start; i <= end; i++) {
            result.push(new DropdownModel(i, this.ordinalSuffixOf(i)));
        }

        return result;
    }

    ordinalSuffixOf(i: number) {
        const j = i % 10;
        const k = i % 100;
        if (j === 1 && k !== 11) {
            return i + "st";
        }
        if (j === 2 && k !== 12) {
            return i + "nd";
        }
        if (j === 3 && k !== 13) {
            return i + "rd";
        }
        return i + "th";
    }
}