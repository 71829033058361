import { ICrewMemberModel } from "./crew-member.model";

export interface IDailyCrewModel {
	dailyCrewId: number;
	resourceId: number;
	resourceName: string;
	resourceBackgroundColor: string;
	resourceSortOrder: number;
	crewMembersDisplay: string;

	crewMembers: ICrewMemberModel[];
}

export class DailyCrewModel implements IDailyCrewModel {
	dailyCrewId: number;
	resourceId: number;
	resourceName: string;
	resourceBackgroundColor: string;
	resourceSortOrder: number;
	crewMembersDisplay: string;

	crewMembers: ICrewMemberModel[];
}
