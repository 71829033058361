// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h5 {
  margin-bottom: 0px;
  font-size: 20px;
}

.resource-label {
  font-size: 12px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
}

label {
  color: var(--bs-gray-600);
}`, "",{"version":3,"sources":["webpack://./app-desktop/src/app/customers/customer-components/customer-edit/job-history/job-history.component.scss"],"names":[],"mappings":"AACA;EACI,kBAAA;EACA,eAAA;AAAJ;;AAGA;EACI,eAAA;EACA,gBAAA;EACA,aAAA;EACA,8BAAA;AAAJ;;AAGA;EACI,yBAAA;AAAJ","sourcesContent":["\r\nh5{\r\n    margin-bottom: 0px;\r\n    font-size: 20px;\r\n}\r\n\r\n.resource-label {\r\n    font-size: 12px;\r\n    font-weight: 400;\r\n    display: flex;\r\n    justify-content: space-between;\r\n}\r\n\r\nlabel{\r\n    color: var(--bs-gray-600);\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
