// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
		.footer-bar {
			height: 1px;
			min-height: 23px;
			line-height: 23px;
		}`, "",{"version":3,"sources":["webpack://./app-desktop/src/app/layout/footer/footer-bar.component.ts"],"names":[],"mappings":";EACE;GACC,WAAW;GACX,gBAAgB;GAChB,iBAAiB;EAClB","sourcesContent":["\n\t\t.footer-bar {\n\t\t\theight: 1px;\n\t\t\tmin-height: 23px;\n\t\t\tline-height: 23px;\n\t\t}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
