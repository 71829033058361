export interface IJobDisplayListAppointmentModel {
    appointmentId: number;
    sequence: number;
    scheduledDateTime: Date;
    scheduledMinutes: number;
    appointmentTypeName: string;
}

export class JobDisplayListAppointmentModel implements IJobDisplayListAppointmentModel {
    appointmentId: number;
    sequence: number;
    scheduledDateTime: Date;
    scheduledMinutes: number;
    appointmentTypeName: string;
}
