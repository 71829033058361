import { JobsAuthService } from '@services/utils/auth-services/jobs-auth.service';
import { Component, Input, EventEmitter } from "@angular/core";
import { IRecurringAppointmentModel } from "@models";

@Component({
    selector: "recurrence-edit",
    templateUrl: "./recurrence-edit.component.html",
    styleUrls: ["./recurrence-edit.component.scss"],
    providers: [JobsAuthService]
})

export class RecurrenceEditComponent {
    @Input() model: IRecurringAppointmentModel;
    @Input() modelChange = new EventEmitter<IRecurringAppointmentModel>();

    constructor(public jobsAuthService: JobsAuthService) {
    }
}