// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.trial {
  visibility: hidden;
  position: absolute;
  left: 0;
  top: 0;
  height: 0;
  transition: height 0.3s ease;
  width: 100%;
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}

.trial.show {
  visibility: visible;
  height: 57px;
}

.trial.hide {
  visibility: visible;
  height: 0;
}

.trial.hide h3 {
  display: none;
}

.trial.show h3 {
  min-width: 100%;
  text-align: center;
  padding-top: 12px !important;
}`, "",{"version":3,"sources":["webpack://./app-desktop/src/app/layout/header/header-bar.component.scss"],"names":[],"mappings":"AAAA;EACC,kBAAA;EACA,kBAAA;EACA,OAAA;EACA,MAAA;EACA,SAAA;EACA,4BAAA;EACA,WAAA;EACA,6CAAA;AACD;;AAEC;EACC,mBAAA;EACA,YAAA;AACF;;AAEC;EACC,mBAAA;EACA,SAAA;AACF;;AAEE;EACC,aAAA;AACH;;AAEE;EACC,eAAA;EACA,kBAAA;EACA,4BAAA;AACH","sourcesContent":[".trial {\r\n\tvisibility: hidden;\r\n\tposition: absolute;\r\n\tleft: 0;\r\n\ttop: 0;\r\n\theight: 0;\r\n\ttransition: height .3s ease;\r\n\twidth: 100%;\r\n\tbox-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);\r\n}\r\n\r\n\t.trial.show {\r\n\t\tvisibility: visible;\r\n\t\theight: 57px;\r\n\t}\r\n\r\n\t.trial.hide {\r\n\t\tvisibility: visible;\r\n\t\theight: 0;\r\n\t}\r\n\r\n\t\t.trial.hide h3 {\r\n\t\t\tdisplay: none;\r\n\t\t}\r\n\r\n\t\t.trial.show h3 {\r\n\t\t\tmin-width: 100%;\r\n\t\t\ttext-align: center;\r\n\t\t\tpadding-top: 12px !important;\r\n\t\t}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
