export interface ILathamCarrierModel {
	lathamCarrierId: string;
	name: string;
}

export class LathamCarrierModel implements ILathamCarrierModel {
	constructor() {
	}

	lathamCarrierId: string;
	name: string;
}