export interface IUnscheduledAppointmentOrder {
	appointmentId: number;
	displaySeq: number;
}

export class UnscheduledAppointmentOrder implements IUnscheduledAppointmentOrder {
	constructor(appointmentId: number, displaySeq: number) {
		this.appointmentId = appointmentId;
		this.displaySeq = displaySeq;
	}

	appointmentId: number;
	displaySeq: number;
}
