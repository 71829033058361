import { ISlickFileModel, SlickFileModel } from "@slick-components";

export interface IDocumentModel extends ISlickFileModel {
	documentId: number;
	documentKey: string;
	folderUuid: string;
	contentType: string;
	mimeType: string;
	isImage: boolean;
	fileName: string;
	fileExt: string;
}

export class DocumentModel extends SlickFileModel implements IDocumentModel {
	documentId: number;
	documentKey: string;
	folderUuid: string;
	contentType: string;
	mimeType: string;
	isImage: boolean;
	fileName: string;
	fileExt: string;
}