// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 768px) {
  * {
    box-sizing: border-box;
  }
  .d-flex {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .thirds > div {
    margin-top: 8px;
    width: calc(33.33% - 8px); /* Divide the container into thirds minus the margin */
    min-width: 100px;
    flex-shrink: 0;
  }
  .d-flex > div:not(:nth-child(3n)) {
    margin-right: 8px; /* Apply margin to every item except every third one */
  }
}
@media (max-width: 768px) {
  * {
    box-sizing: border-box;
  }
  .d-flex {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .thirds > div {
    margin-top: 8px;
    width: calc(50% - 8px); /* Divide the container into thirds minus the margin */
    min-width: 100px;
    flex-shrink: 0;
  }
  .d-flex > div:not(:nth-child(3n)) {
    margin-right: 8px; /* Apply margin to every item except every third one */
  }
}`, "",{"version":3,"sources":["webpack://./libraries/shared-components/jelly-fish-order-specs/jelly-fish-order-specs.scss"],"names":[],"mappings":"AAAA;EACI;IACI,sBAAA;EACN;EAEE;IACI,aAAA;IACA,eAAA;IACA,WAAA;EAAN;EAGE;IACI,eAAA;IACA,yBAAA,EAAA,sDAAA;IACA,gBAAA;IACA,cAAA;EADN;EAIE;IAEI,iBAAA,EAAA,sDAAA;EAHN;AACF;AAMA;EACI;IACI,sBAAA;EAJN;EAOE;IACI,aAAA;IACA,eAAA;IACA,WAAA;EALN;EAQE;IACI,eAAA;IACA,sBAAA,EAAA,sDAAA;IACA,gBAAA;IACA,cAAA;EANN;EASE;IACI,iBAAA,EAAA,sDAAA;EAPN;AACF","sourcesContent":["@media (min-width: 768px) {\r\n    * {\r\n        box-sizing: border-box;\r\n    }\r\n\r\n    .d-flex {\r\n        display: flex;\r\n        flex-wrap: wrap;\r\n        width: 100%;\r\n    }\r\n\r\n    .thirds > div {\r\n        margin-top: 8px;\r\n        width: calc(33.33% - 8px); /* Divide the container into thirds minus the margin */\r\n        min-width: 100px;\r\n        flex-shrink: 0;\r\n    }\r\n\r\n    .d-flex > div:not(:nth-child(3n)) {\r\n        \r\n        margin-right: 8px; /* Apply margin to every item except every third one */\r\n    }\r\n}\r\n\r\n@media (max-width: 768px) {\r\n    * {\r\n        box-sizing: border-box;\r\n    }\r\n\r\n    .d-flex {\r\n        display: flex;\r\n        flex-wrap: wrap;\r\n        width: 100%;\r\n    }\r\n\r\n    .thirds > div {\r\n        margin-top: 8px;\r\n        width: calc(50% - 8px); /* Divide the container into thirds minus the margin */\r\n        min-width: 100px;\r\n        flex-shrink: 0;\r\n    }\r\n\r\n    .d-flex > div:not(:nth-child(3n)) {\r\n        margin-right: 8px; /* Apply margin to every item except every third one */\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
