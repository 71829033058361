// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-x: auto;
}

.main-container .content {
  min-height: calc(100vh - 26px);
  max-height: calc(100vh - 26px);
  display: flex;
  flex-direction: row;
}

.main-container .content .main-area-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  width: calc(100vw - 50px);
  transition: linear 0.3s;
}

.main-container .content .main-area-container.menu-is-open {
  width: calc(100vw - 200px);
  transition: linear 0.3s;
}

.main-container .content .main-area-container .main-area {
  flex: 1;
  max-height: calc(100vh - 79px);
  overflow: hidden;
}

.main-container .side-menu-container {
  display: block;
}

.main-container .menu-border {
  width: 1px;
  /*min-height: calc(100vh - 24px);*/
}`, "",{"version":3,"sources":["webpack://./app-desktop/src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,sBAAA;EACA,OAAA;EACA,gBAAA;AACD;;AAEA;EACC,8BAAA;EACA,8BAAA;EACA,aAAA;EACA,mBAAA;AACD;;AAEA;EACC,OAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,yBAAA;EACA,uBAAA;AACD;;AAEA;EACC,0BAAA;EACA,uBAAA;AACD;;AAEA;EACC,OAAA;EACA,8BAAA;EACA,gBAAA;AACD;;AAEA;EACC,cAAA;AACD;;AAEA;EACC,UAAA;EACA,kCAAA;AACD","sourcesContent":[".main-container {\r\n\tdisplay: flex;\r\n\tflex-direction: column;\r\n\tflex: 1;\r\n\toverflow-x: auto; \r\n}\r\n\r\n.main-container .content {\r\n\tmin-height: calc(100vh - 26px);\r\n\tmax-height: calc(100vh - 26px);\r\n\tdisplay: flex;\r\n\tflex-direction: row;\r\n}\r\n\r\n.main-container .content .main-area-container {\r\n\tflex: 1;\r\n\tdisplay: flex;\r\n\tflex-direction: column;\r\n\tposition: relative;\r\n\twidth: calc(100vw - 50px);\r\n\ttransition: linear 0.3s;\r\n}\r\n\r\n.main-container .content .main-area-container.menu-is-open {\r\n\twidth: calc(100vw - 200px);\r\n\ttransition: linear 0.3s;\r\n}\r\n\r\n.main-container .content .main-area-container .main-area {\r\n\tflex: 1;\r\n\tmax-height: calc(100vh - 79px);\r\n\toverflow: hidden;\r\n}\r\n\r\n.main-container .side-menu-container {\r\n\tdisplay: block;\r\n}\r\n\r\n.main-container .menu-border {\r\n\twidth: 1px;\r\n\t/*min-height: calc(100vh - 24px);*/\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
