import { Component, Input, Output, EventEmitter } from "@angular/core";
import { ICustomerContactModel, CustomerContactModel } from "@models";
import { CustomersService } from "@services";

@Component({
	selector: "contact-edit",
    templateUrl: "contact-edit.component.html",
    providers: [CustomersService]
})

export class ContactEditComponent {
    @Input() contact: ICustomerContactModel;
    @Output() contactChange = new EventEmitter<ICustomerContactModel>();

    countries: any = [
        { id: "US", text: "US" },
        { id: "Canada", text: "Canada" },
        { id: "Mexico", text: "Mexico" },
        { id: "Israel", text: "Israel" },
    ];

    constructor() {
        if (!this.contact)
            this.contact = new CustomerContactModel();
    }

    saveContact() {
        this.contactChange.emit(this.contact);
    }

    cancelContact() {
        this.contactChange.emit(null);
    }

    onGoogleMapAddressSelect(placeResult: google.maps.places.PlaceResult) {
        this.setAddress(placeResult);
    }

    private setAddress( placeResult: google.maps.places.PlaceResult) {
        this.contact.address1 = "";
        this.contact.city = "";
        this.contact.state = "";
        this.contact.zipcode = "";
        this.contact.country = "";

        placeResult.address_components.forEach(adr => {
            adr.types.forEach(type => {
                switch (type) {
                case "street_number":
                case "route":
                    this.contact.address1 += adr.long_name + " ";
                    break;

                case "locality":
                    this.contact.city = adr.long_name;
                    break;

                case "administrative_area_level_1":
                    this.contact.state = adr.short_name;
                    break;

                case "postal_code":
                    this.contact.zipcode = adr.short_name;
                    break;

                case "country":
                    if (adr.short_name === "CA") {
                        this.contact.country = "Canada";
                    } else if (adr.short_name === "IL") {
                        this.contact.country = "Israel";
                    } else if (adr.short_name === "MX") {
                        this.contact.country = "Mexico";
                    } else {
                        this.contact.country = adr.short_name;
                    }
                    break;
                };
            });
        });
    }
}