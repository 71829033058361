export interface ISlickDropDownButtonModel {
    onClick: Function;
    faClass: string;
    tabIndex: number;
    disabled: boolean;
}

export class SlickDropDownButtonModel implements ISlickDropDownButtonModel {

    constructor(faClass: string = null, onClick: Function = null) {
        this.onClick = onClick;
        this.faClass = faClass;
        this.tabIndex = -1;
    }

    onClick: Function;
    faClass: string;
    tabIndex: number;
    disabled: boolean;
}