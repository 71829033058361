import { ICustomerModel, CustomerModel } from "../customers/customer.model"
import { IJobDisplayListAppointmentModel, JobDisplayListAppointmentModel } from "./job-display-list-appointment.model";

export interface IJobDisplayListModel  {
    jobId: number;
    jobName: string;
    jobCustomer: ICustomerModel;
    billToCustomer: ICustomerModel;
    jobTypeDescription: string;
    jobStatusDescription: string;
    totalOpenAppointments: number;
    nextAppointment: IJobDisplayListAppointmentModel;
    firstPreviousAppointment: IJobDisplayListAppointmentModel;
    allUpcomingAppointments: IJobDisplayListAppointmentModel[];
    allPreviousAppointments: IJobDisplayListAppointmentModel[];
    jobNotes: string;
}

export class JobDisplayListModel implements IJobDisplayListModel {
    jobId: number;
    jobName: string;
    jobCustomer: ICustomerModel;
    billToCustomer: ICustomerModel;
    jobTypeDescription: string;
    jobStatusDescription: string;
    totalOpenAppointments: number;
    nextAppointment: IJobDisplayListAppointmentModel;
    firstPreviousAppointment: IJobDisplayListAppointmentModel;
    allUpcomingAppointments: IJobDisplayListAppointmentModel[];
    allPreviousAppointments: IJobDisplayListAppointmentModel[];
    jobNotes: string;
}