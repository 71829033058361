import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { SlickProgressBarService } from "./slick-progress-bar.service";

@NgModule({
	imports: [RouterModule],
	providers: [SlickProgressBarService]
})
export class SlickProgressBarModule { }

export { SlickProgressBarService }