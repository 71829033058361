// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.top-group {
	display: flex;
	flex: 1;
	flex-direction: row;
	width: 300px;
	margin-top: 8px;
}

.add-btn {
	margin-left: 8px;
}

.card {
	width: 300px;
	box-shadow: 2px 2px 6px rgba(0,0,0,0.2);
}

.card-body {
	width: 200px;
}

.sort-icon {
	flex: .1 0 auto;
	padding-top: 1.5rem;
	cursor: ns-resize;
}

.layout-body {
	width: calc(100% - 300px);
	float: right;
	margin-top: 5px;
}

@media screen and (max-width: 1199px) {
	.default-layout {
		width: 180px;
	}

	.top-group {
		width: 180px;
	}

	.layout-body {
		width: calc(100% - 180px);
	}

	.card {
		width: 180px;
	}

	.card-body {
		width: 140px;
	}

}

@media screen and (max-width: 991px) {
	.hide-lg {
		display: none;
	}

	.default-layout {
		width: 100%;
	}

	.top-group {
		width: 100%;
	}

	.card {
		width: 100%;
	}

	.card-body {
		width: 100px;
	}

	.layout-body {
		width: 100%;
	}
}
`, "",{"version":3,"sources":["webpack://./app-desktop/src/components/default-layout/default-layout.component.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,OAAO;CACP,mBAAmB;CACnB,YAAY;CACZ,eAAe;AAChB;;AAEA;CACC,gBAAgB;AACjB;;AAEA;CACC,YAAY;CACZ,uCAAuC;AACxC;;AAEA;CACC,YAAY;AACb;;AAEA;CACC,eAAe;CACf,mBAAmB;CACnB,iBAAiB;AAClB;;AAEA;CACC,yBAAyB;CACzB,YAAY;CACZ,eAAe;AAChB;;AAEA;CACC;EACC,YAAY;CACb;;CAEA;EACC,YAAY;CACb;;CAEA;EACC,yBAAyB;CAC1B;;CAEA;EACC,YAAY;CACb;;CAEA;EACC,YAAY;CACb;;AAED;;AAEA;CACC;EACC,aAAa;CACd;;CAEA;EACC,WAAW;CACZ;;CAEA;EACC,WAAW;CACZ;;CAEA;EACC,WAAW;CACZ;;CAEA;EACC,YAAY;CACb;;CAEA;EACC,WAAW;CACZ;AACD","sourcesContent":[".top-group {\r\n\tdisplay: flex;\r\n\tflex: 1;\r\n\tflex-direction: row;\r\n\twidth: 300px;\r\n\tmargin-top: 8px;\r\n}\r\n\r\n.add-btn {\r\n\tmargin-left: 8px;\r\n}\r\n\r\n.card {\r\n\twidth: 300px;\r\n\tbox-shadow: 2px 2px 6px rgba(0,0,0,0.2);\r\n}\r\n\r\n.card-body {\r\n\twidth: 200px;\r\n}\r\n\r\n.sort-icon {\r\n\tflex: .1 0 auto;\r\n\tpadding-top: 1.5rem;\r\n\tcursor: ns-resize;\r\n}\r\n\r\n.layout-body {\r\n\twidth: calc(100% - 300px);\r\n\tfloat: right;\r\n\tmargin-top: 5px;\r\n}\r\n\r\n@media screen and (max-width: 1199px) {\r\n\t.default-layout {\r\n\t\twidth: 180px;\r\n\t}\r\n\r\n\t.top-group {\r\n\t\twidth: 180px;\r\n\t}\r\n\r\n\t.layout-body {\r\n\t\twidth: calc(100% - 180px);\r\n\t}\r\n\r\n\t.card {\r\n\t\twidth: 180px;\r\n\t}\r\n\r\n\t.card-body {\r\n\t\twidth: 140px;\r\n\t}\r\n\r\n}\r\n\r\n@media screen and (max-width: 991px) {\r\n\t.hide-lg {\r\n\t\tdisplay: none;\r\n\t}\r\n\r\n\t.default-layout {\r\n\t\twidth: 100%;\r\n\t}\r\n\r\n\t.top-group {\r\n\t\twidth: 100%;\r\n\t}\r\n\r\n\t.card {\r\n\t\twidth: 100%;\r\n\t}\r\n\r\n\t.card-body {\r\n\t\twidth: 100px;\r\n\t}\r\n\r\n\t.layout-body {\r\n\t\twidth: 100%;\r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
