// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qbwcTaskDetailLabel {
  text-align: right;
  display: inline-block;
  width: 170px;
  margin-right: 5px;
  vertical-align: top;
  line-height: 1.1em;
}

.qbwcTaskDetailText {
  vertical-align: top;
  display: inline-block;
  width: calc(100% - 190px);
  line-height: 1.1em;
}`, "",{"version":3,"sources":["webpack://./app-desktop/src/app/quickbooks/quickbooks-task-display/quickbooks-task-display.component.scss"],"names":[],"mappings":"AAAA;EACC,iBAAA;EACA,qBAAA;EACA,YAAA;EACA,iBAAA;EACA,mBAAA;EACA,kBAAA;AACD;;AAEA;EACC,mBAAA;EACA,qBAAA;EACA,yBAAA;EACA,kBAAA;AACD","sourcesContent":[".qbwcTaskDetailLabel {\r\n\ttext-align: right;\r\n\tdisplay: inline-block;\r\n\twidth: 170px;\r\n\tmargin-right: 5px;\r\n\tvertical-align: top;\r\n\tline-height: 1.1em;\r\n}\r\n\r\n.qbwcTaskDetailText {\r\n\tvertical-align: top;\r\n\tdisplay: inline-block;\r\n\twidth: calc(100% - 190px);\r\n\tline-height: 1.1em;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
