export interface INewAppointment {
    resourceId: number;
    startDate: Date;
    duration: number;
}

export class NewAppointment implements INewAppointment {
    resourceId: number;
    startDate: Date;
    duration: number;
}