import { IJobModel } from "../jobs/job.model";

export interface IInventoryPickListModel {
    resourceName: string;
    jobModels: IJobModel[];

}

export class InventoryPickListModel implements IInventoryPickListModel {
    resourceName: string;
    jobModels: IJobModel[];
}