// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo-header {
  font-weight: bold;
  font-size: 20px;
  padding-top: 20px;
  padding-bottom: 5px;
}

.gray-line {
  border-bottom: 2px solid var(--bs-border-color);
  flex-grow: 100;
  margin-top: 2px;
}`, "",{"version":3,"sources":["webpack://./app-desktop/src/app/estimates/estimates-components/estimate-edit/estimate-edit.component.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,+CAAA;EACA,cAAA;EACA,eAAA;AACJ","sourcesContent":[".logo-header {\r\n    font-weight: bold;\r\n    font-size: 20px;\r\n    padding-top: 20px;\r\n    padding-bottom: 5px;\r\n}\r\n\r\n.gray-line {\r\n    border-bottom: 2px solid var(--bs-border-color);\r\n    flex-grow: 100;\r\n    margin-top: 2px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
