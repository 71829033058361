// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-self {
  border-radius: 6px;
  padding: 6px;
  line-height: 1rem;
}

.chat-other {
  border-radius: 6px;
  padding: 6px;
  line-height: 1rem;
}

img {
  max-width: 300px;
  max-height: 300px;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.125);
}`, "",{"version":3,"sources":["webpack://./app-desktop/src/app/chat/components/chat-messages/chat-messages.component.scss"],"names":[],"mappings":"AAAA;EACC,kBAAA;EACA,YAAA;EACA,iBAAA;AACD;;AAEA;EACC,kBAAA;EACA,YAAA;EACA,iBAAA;AACD;;AAEA;EACC,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,sCAAA;AACD","sourcesContent":[".chat-self {\r\n\tborder-radius: 6px;\r\n\tpadding: 6px;\r\n\tline-height: 1rem;\r\n}\r\n\r\n.chat-other {\r\n\tborder-radius: 6px;\r\n\tpadding: 6px;\r\n\tline-height: 1rem;\r\n}\r\n\r\nimg {\r\n\tmax-width: 300px;\r\n\tmax-height: 300px;\r\n\tborder-radius: 6px;\r\n\tborder: 1px solid rgba(0,0,0,.125);\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
