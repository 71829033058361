export interface IChatGroupAttendantModel {
    chatGroupId: number;
    userId: number;
    teamId: number;
    isAllUsers: boolean;
    invitedById: number;
    joinedOn: Date;
    lastTimeActive: Date;
    fullName: string;
    profilePictureUrl: string;
}

export class ChatGroupAttendantModel implements IChatGroupAttendantModel {
    chatGroupId: number;
    userId: number;
    teamId: number;
    isAllUsers: boolean;
    invitedById: number;
    joinedOn: Date;
    lastTimeActive: Date;
    fullName: string;
    profilePictureUrl: string;
}