// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.full-screen {
  min-height: calc(100vh - 212px);
  max-height: calc(100vh - 212px);
  overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./app-desktop/src/app/customers/customer-components/customer-edit/customer-edit.component.scss"],"names":[],"mappings":"AAAA;EACC,+BAAA;EACA,+BAAA;EACA,gBAAA;AACD","sourcesContent":[".full-screen {\r\n\tmin-height: calc(100vh - 212px);\r\n\tmax-height: calc(100vh - 212px);\r\n\toverflow-y: auto;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
