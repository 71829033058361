export interface IUserPermissionModel {
	key: string;
    feature: string;
    action: string;
	granted: boolean;
	isDefault: boolean;
	isUserAdmin: boolean;
}

export class UserPermissionModel implements IUserPermissionModel {
	key: string;
    feature: string;
    action: string;
	granted: boolean;
	isDefault: boolean;
	isUserAdmin: boolean;
}

