import { GlobalsService } from "@services/utils/globals.service";
import { IInvoiceModel, InvoiceModel } from "../invoices/invoice.model";
import { ILathamOrderSpecsModel, LathamOrderSpecsModel } from "./latham-order-specs.model";
import { ILathamSalesOrderLineItemModel } from "./latham-sales-order-line-item.model";

export interface ILathamOrderModel {
    companyId: number;
    lathamOrderId: number;
    lathamOrderGroupUuid: string;
    version: number;
    orderId: string;
    actualOrderId: string;
    originalOrderId: string;
    documentKey: string;
    childDocumentKey?: string;
    orderStatus: string;
    lastEditDate: Date;
    lastEditDateTicks: string;
    lastEditedBy: string;
    orderSpecs: ILathamOrderSpecsModel;
    processingFavorite: boolean;
    isSystem: boolean;
    isSentry: boolean;
	isCover: boolean;
	isManual: boolean;
	isLowOffset: boolean;
	brandType: string;
	modelType: string;
    builderNotes: string;
	orderNotes: string;
	officeNotes: string;
    quoteSentDate: Date;
    quoteSentTo: string;
    quoteAcceptedDate: Date;
    quoteAcceptedBy: string;
    submittedDate: Date;
    lathamDueDate: Date;
    lathamShipDate: Date;
    lathamOrderStatus: string;
    lathamProNumber: string;
    lathamCompletedDate: Date;
    modifiedByLatham: boolean;
    mistakeType: string;
    mechanismDashboard: boolean;
    approvalEmailSentTo: string;
    approvalEmailSentBy: string;
    approvalEmailSentDate?: Date;
    approvedBy: string;
    approvalDate?: Date;
    approvalIPAddress: string;
    additionalOrders: string[];
    invoice: IInvoiceModel;
    lathamSalesOrderLineItems: ILathamSalesOrderLineItemModel[];
    sqFtPopUpShown: boolean;
}

export class LathamOrderModel implements ILathamOrderModel
{
    constructor() {
        this.orderSpecs = new LathamOrderSpecsModel();
        this.invoice = new InvoiceModel();
    }

    companyId: number;
    lathamOrderId: number;
    lathamOrderGroupUuid: string;
    orderId: string;
    version: number;
    actualOrderId: string;
    originalOrderId: string;
    documentKey: string;
    childDocumentKey: string;
    orderStatus: string;
    lastEditDate: Date;
    lastEditDateTicks: string;
    lastEditedBy: string;
    orderSpecs: ILathamOrderSpecsModel;
    processingFavorite: boolean;
    isSystem: boolean;
    isSentry: boolean;
	isCover: boolean;
	isManual: boolean;
	isLowOffset: boolean;
	brandType: string;
	modelType: string;
    builderNotes: string;
	orderNotes: string;
	officeNotes: string;
    quoteSentDate: Date;
    quoteSentTo: string;
    quoteAcceptedDate: Date;
    quoteAcceptedBy: string;
    submittedDate: Date;
    lathamDueDate: Date;
    lathamShipDate: Date;
    lathamOrderStatus: string;
    lathamProNumber: string;
    lathamCompletedDate: Date;
    modifiedByLatham: boolean;
    mistakeType: string;
    mechanismDashboard: boolean;
    approvalEmailSentTo: string;
    approvalEmailSentBy: string;
    approvalEmailSentDate?: Date;
    approvedBy: string;
    approvalDate?: Date;
    approvalIPAddress: string;
    additionalOrders: string[];
    invoice: IInvoiceModel;
    lathamSalesOrderLineItems: ILathamSalesOrderLineItemModel[];
    sqFtPopUpShown: boolean;
}