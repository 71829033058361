import { IMaintenanceReadingValueModel } from "./maintenance-reading-value.model";

export interface IMaintenanceReadingModel {
	maintenanceReadingId: number;
	customerId: number;
	appointmentId?: number;
	maintenanceReadingDate: Date;
	maintenanceReadingValues: IMaintenanceReadingValueModel[];
}

export class MaintenanceReadingModel implements IMaintenanceReadingModel {
	constructor() {
		this.maintenanceReadingId = 0;
		this.maintenanceReadingValues = []
    }
	maintenanceReadingId: number;
	customerId: number;
	appointmentId?: number;
	maintenanceReadingDate: Date;
	maintenanceReadingValues: IMaintenanceReadingValueModel[];
}