import { IScheduledAppointmentModel } from "../appointments/scheduled-appointment.model";
import { IBlockedTimeSlotModel } from "../appointments/blocked-time-slots.model";
import { IDailyPreChecklistModel } from "../daily-pre-checklist/daily-pre-checklist.model";

export interface IServiceTechScheduleDateModel {
	scheduleDate: Date;
	startHour: number;
	endHour: number;
	scheduledAppointments: IScheduledAppointmentModel[];
	optimizedScheduledAppointments: IScheduledAppointmentModel[];
	blockedTimeSlots: IBlockedTimeSlotModel[]
	dailyPreChecklist: IDailyPreChecklistModel;
	isExpanded: boolean;
	optimalRouteCalculated: boolean;
}

export class ServiceTechScheduleDateModel implements IServiceTechScheduleDateModel {
	constructor(date: Date) {
		this.scheduleDate = date;
		this.isExpanded = false;
		this.scheduledAppointments = null;
		this.blockedTimeSlots = null;
		this.optimalRouteCalculated = false;
	}

	scheduleDate: Date;
	startHour: number;
	endHour: number;
	scheduledAppointments: IScheduledAppointmentModel[];
	optimizedScheduledAppointments: IScheduledAppointmentModel[];
	blockedTimeSlots: IBlockedTimeSlotModel[]
	dailyPreChecklist: IDailyPreChecklistModel;
	isExpanded: boolean;
	optimalRouteCalculated: boolean;
}