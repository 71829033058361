import { Injectable } from "@angular/core";

@Injectable()
export class SlickMultiSelectService {
	public getVisibleItems(items: any[], selectedIds: string[], idFieldName: string, textFieldName: string): any[] {
		let foundSomeText: boolean = false;
		
		if (!items || items.length === 0)
			return null;

		let visibleItems: any[] = items.map((item) => {
			let id = item[idFieldName];						
			if (id === null || id === undefined) {
				console.error("%c Id not found for multiSelect item.  The idFieldName is '" + idFieldName + "'.  Is this correct?", 'background: red; color: white');
				console.error("First Item: ", items[0]);
			}

			if (!selectedIds || (selectedIds.indexOf(id) === -1 && selectedIds.indexOf(id.toString()) === -1)) {
				let text = item[textFieldName];
				if (text !== null && text !== undefined)
					foundSomeText = true;

				return {
					id: id,
					text: (text ?? "").toString(),
					item: item
				}
			}
		});

		visibleItems = visibleItems.filter(x => !!x);

		if (visibleItems.length === 0)
			return null;

		if (foundSomeText === false) {
			console.error("%c Warning!  No text was found for multiSelect item.  The textFieldName is '" + textFieldName + "'.  Is this correct?", 'background: red; color: white');
			console.error("First Item: ", items[0]);
		}


		return visibleItems;
	}

	public getSelectedItems(items: any[], selectedIds: string[], idFieldName: string, textFieldName: string): any[] {
		let foundSomeText: boolean = false;

		if (!items || items.length === 0 || !selectedIds || selectedIds.length === 0)
			return null;

		let selectedItems: any[] = selectedIds.map((id: string) => {
			let item = items.find(x => x[idFieldName] == id);
			
			if (item === null || item === undefined) {
				console.error(`%c Id ${id} not found for multiSelect item.  The idFieldName is ${idFieldName}.  Is this correct?`, 'background: red; color: white');
				console.error("First Item: ", items[0]);
				return null;
			}

			let text = item[textFieldName];
			if (text !== null && text !== undefined)
				foundSomeText = true;

			return {
				id: id,
				text: text ? text.toString() : null
			}
		});

		if (foundSomeText === false) {
			console.error("%c Warning!  No text was found for multiSelect item.  The textFieldName is '" + textFieldName + "'.  Is this correct?", 'background: red; color: white');
			console.error("First Item: ", items[0]);
		}

		selectedItems = selectedItems.filter(x => x !== null);

		return selectedItems;
	}

	public getSelectedItem(items: any[], selectedId: string): any {
		if (!items || items.length === 0)
			return null;

		let selectedItem = items.find((item) => { return item.id === selectedId });

		if (selectedItem)
			return selectedItem;
		else
			return null;
	}

	public getItemIndex(items: any[], selectedId: string): number {
		return items.findIndex((item) => { return item.id === selectedId });
	}
}