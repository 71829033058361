import { CustomersAuthService } from '@services/utils/auth-services/customers-auth.service';
import { Component, Input, ViewChild } from "@angular/core";
import {  UtilsService, LookupService,  CustomersService } from "@services";
import { CustomerContactModel, ICustomerModel, IDropdownModel } from "@models";
import { ContactEditDialogComponent, ContactEditContext } from "../contact-edit-dialog/contact-edit-dialog.component";

@Component({
    selector: 'contact-edit-layout',
    templateUrl: './contact-edit-layout.component.html',
    styleUrls: ['./contact-edit-layout.component.scss'],
    providers: [CustomersService, CustomersAuthService]
})
export class ContactEditLayoutComponent {
    @Input() customerModel: ICustomerModel;
    @ViewChild("contactEditDialogRef", { static: true }) contactEditDialogRef: ContactEditDialogComponent;

    customerTypes: IDropdownModel[];
    priceLevels: IDropdownModel[];
    paymentTerms: IDropdownModel[];
    customerAreas: IDropdownModel[];
    taxRegions: IDropdownModel[];
    isSubmitted: boolean;

    countries: any = [
        { id: 'US', text: 'US' },
        { id: 'Canada', text: 'Canada' },
        { id: 'Mexico', text: 'Mexico' },
        { id: 'Israel', text: 'Israel' },
    ]

    constructor(private lookupService: LookupService, private customersService: CustomersService, public customersAuthService: CustomersAuthService) {
        this.customerTypes = this.lookupService.getCustomerTypesForDropdown();
        this.priceLevels = this.lookupService.getPriceLevelsForDropdown();
        this.paymentTerms = this.lookupService.getPaymentTermsForDropdown();
        this.customerAreas = this.lookupService.getCustomerAreasForDropdown();
        this.taxRegions = this.lookupService.getTaxRegionsForDropdown();
    }

    onContactChange(contactContext: ContactEditContext) {
        if (contactContext.index > -1) {
            this.customerModel.contacts[contactContext.index] = contactContext.contact;
        }
        else {
            this.customerModel.contacts.push(contactContext.contact);
        }
    }

    removeContact(index: number, e: Event) {
        e.preventDefault();
        e.stopPropagation();
        this.customerModel.contacts.splice(index, 1);
    }

    openContact(item, index: number) {
        const context = new ContactEditContext();
        context.contact = item ? UtilsService.clone(item) : new CustomerContactModel();
        context.index = index;
        this.contactEditDialogRef.openDialog(context);
    }
}