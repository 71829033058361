// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-user-img-offset {
  margin-left: -34px;
}`, "",{"version":3,"sources":["webpack://./app-desktop/src/app/chat/components/chat-group-list/chat-group-list.component.scss"],"names":[],"mappings":"AAAA;EACC,kBAAA;AACD","sourcesContent":[".chat-user-img-offset {\r\n\tmargin-left: -34px;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
