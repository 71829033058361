export interface IAppointmentNotesUpdate {
    appointmentId: number;
    notes: string;
    customerNotes: string;
}

export class AppointmentNotesUpdate implements IAppointmentNotesUpdate {
    appointmentId: number;
    notes: string;
    customerNotes: string;
}