import { Injectable} from '@angular/core';
import { HttpService } from './utils/http.service';

@Injectable()
export class ChecklistService {

	constructor(private readonly httpService: HttpService) { }

	async getChecklistQuestionsByAppointmentTypeId(appointmentTypeId) {
		return await this.httpService.get("/checklist/GetChecklistQuestionsByAppointmentTypeId", { appointmentTypeId: appointmentTypeId })
	}

	async setAppointmentChecklistQuestion(appointmentChecklistQuestionId: number, isChecked: boolean) {
		return await this.httpService.patch(`/checklist/setAppointmentChecklistQuestion?appointmentChecklistQuestionId=${appointmentChecklistQuestionId}&isChecked=${isChecked}`);
	}
}
