export interface ICustomerContactModel {
	customerContactId: number;
	companyName: string;
	firstName: string;
	lastName: string;
	displayName: string;
	address1: string;
	address2: string;
	city: string;
	state: string;
	zipcode: string;
	country: string;
	email: string;
	mainPhone: string;
	homePhone: string;
	cellPhone: string;
	workPhone: string;
}

export class CustomerContactModel implements ICustomerContactModel { 
	constructor() {
		this.customerContactId = 0;
	}

	customerContactId: number;
	companyName: string;
	firstName: string;
	lastName: string;
	displayName: string;
	address1: string;
	address2: string;
	city: string;
	state: string;
	zipcode: string;
	country: string;
	email: string;
	mainPhone: string;
	homePhone: string;
	cellPhone: string;
	workPhone: string;
}
