import { Component, forwardRef } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { SlickUtilsService } from "../utils/slick-utils.service";
import { ISlickEmailerModel, } from "../slick-emailer/slick-emailer.model";

@Component({
	selector: 'slick-email-viewer',
	templateUrl: 'slick-email-viewer.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SlickEmailViewerComponent),
			multi: true,
		}]
})
export class SlickEmailViewerComponent {
	uuid: string;
	emailerModel: ISlickEmailerModel;

	constructor() {
		this.uuid = SlickUtilsService.newGuid();
	}

	propagateChange = (_: any) => { };

	// this is the initial value set to the component
	public async writeValue(obj: ISlickEmailerModel) {
		this.emailerModel = obj;
	}

	// registers 'fn' that will be fired when changes are made
	// this is how we emit the changes back to the form
	public registerOnChange(fn: any) {
		this.propagateChange = fn;
	}

	// not used, used for touch input
	public registerOnTouched() { }


}
