import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, OnChanges, SimpleChanges, OnDestroy } from "@angular/core";

@Component({
	selector: "slick-split-screen",
	templateUrl: 'slick-split-screen.component.html'
})
export class SlickSplitScreenComponent implements  OnChanges, OnDestroy {
	@Input() orientation: string = 'vertical';
	@Input() allowSwitch: boolean = true;
	@Input() key: string;
	@Input() verticalSplitPercent: number = 75;
	@Input() horizontalSplitPercent: number = 60;
	@Output() onResizeStart: EventEmitter<void> = new EventEmitter<void>();
	@Output() onResizeEnd: EventEmitter<void> = new EventEmitter<void>();
	@ViewChild("slickSplitScreenContainer") slickSplitScreenContainer: ElementRef;
	@ViewChild("slickSplitScreenLeftContent") slickSplitScreenLeft: ElementRef;
	@ViewChild("slickSplitScreenRightContent") slickSplitScreenRight: ElementRef;

	currentOrientation: string = 'vertical';
	leftWidth: string;
	leftHeight: string;
	rightWidth: string;
	rightHeight: string;
	clientRectTop: number;
	clientRectLeft: number;
	fullWidthPx: number;
	fullHeightPx: number;
	splitBarMiddleTop: string;
	splitBarMiddleLeft: string;
	resizing: boolean = false;
	private fnOnMouseMove = (e) => this.onMouseMove(e);
	private fnOnMouseUp = (e) => this.onMouseUp(e);
	private throttleTimeout: NodeJS.Timer = null;

	constructor() { }

	ngOnChanges(changes: SimpleChanges) {
		if (changes.orientation) {
			if (changes.orientation.isFirstChange() === true && this.key)
				this.currentOrientation = localStorage.getItem("SLICK_SPLIT_SCREEN_ORIENTATION_" + this.key) || changes.orientation.currentValue;

			else
				this.currentOrientation = changes.orientation.currentValue;
		}

		if (changes.verticalSplitPercent) {
			if (changes.verticalSplitPercent.isFirstChange() === true && this.key)
				this.verticalSplitPercent = parseInt(localStorage.getItem("SLICK_SPLIT_SCREEN_VERTICAL_SPLIT_PERCENT_" + this.key) || this.verticalSplitPercent.toString());
			else
				this.verticalSplitPercent = changes.verticalSplitPercent.currentValue;
		}
		
		if (changes.horizontalSplitPercent) {
			if (changes.horizontalSplitPercent.isFirstChange() === true && this.key)
				this.horizontalSplitPercent = parseInt(localStorage.getItem("SLICK_SPLIT_SCREEN_HORIZONTAL_SPLIT_PERCENT_" + this.key) || this.horizontalSplitPercent.toString());
			else
				this.horizontalSplitPercent = changes.horizontalSplitPercent.currentValue;
		}

		this.resize();
	}

	ngOnDestroy() {
		document.removeEventListener('mousemove', this.fnOnMouseMove);
		document.removeEventListener('mouseup', this.fnOnMouseUp);
	}

	changeOrientation() {
		if (this.currentOrientation === 'vertical')
			this.currentOrientation = 'horizontal';
		else
			this.currentOrientation = 'vertical';

		this.resize();
	}

	onDragStart(e) {
		return false;
	}

	onMouseDown(e) {
		e.preventDefault();

		if (this.onResizeStart)
			this.onResizeStart.emit();

		if (this.currentOrientation === "vertical")
			document.body.style.cursor = "col-resize";
		else
			document.body.style.cursor = "row-resize";
		this.resizing = true;
		this.fullWidthPx = this.slickSplitScreenContainer.nativeElement.offsetWidth;
		this.fullHeightPx = this.slickSplitScreenContainer.nativeElement.offsetHeight;

		let rect = this.slickSplitScreenLeft.nativeElement.getBoundingClientRect();
		this.clientRectLeft = rect.left;
		this.clientRectTop = rect.top;
		this.resize();

		document.addEventListener('mousemove', this.fnOnMouseMove);
		document.addEventListener('mouseup', this.fnOnMouseUp);
	}

	private onMouseUp(e) {
		this.resizing = false;
		document.body.style.cursor = "default";
		document.removeEventListener('mousemove', this.fnOnMouseMove);
		document.removeEventListener('mouseup', this.fnOnMouseUp);

		if (this.onResizeEnd)
			this.onResizeEnd.emit();
	}

	private onMouseMove(e) {
		e.preventDefault();

		if (this.throttleTimeout)
			return;

		this.throttleTimeout = setTimeout(() => {
			if (this.currentOrientation === 'vertical') {
				let adjustedLeft: number = e.pageX - this.clientRectLeft;
				this.verticalSplitPercent = (adjustedLeft / this.fullWidthPx) * 100;
				if (this.verticalSplitPercent > 80)
					this.verticalSplitPercent = 80;

				if (this.verticalSplitPercent < 20)
					this.verticalSplitPercent = 20;
			}
			else {
				let adjustedTop: number = e.pageY - this.clientRectTop;
				this.horizontalSplitPercent = (adjustedTop / this.fullHeightPx) * 100;
				if (this.horizontalSplitPercent > 80)
					this.horizontalSplitPercent = 80;

				if (this.horizontalSplitPercent < 20)
					this.horizontalSplitPercent = 20;
			}

			this.resize();
			this.throttleTimeout = null;
		}, 25);

	}

	private resize() {
		if (this.currentOrientation === "vertical") {
			this.leftWidth = "calc(" + this.verticalSplitPercent + "% - 1px)";
			this.leftHeight = "100%";
			this.rightWidth = (100 - this.verticalSplitPercent) + "%";
			this.rightHeight = "100%";
			this.splitBarMiddleTop = "0";
			this.splitBarMiddleLeft = "calc(" + this.verticalSplitPercent + "% - 4px)";
			if (this.key)
				localStorage.setItem("SLICK_SPLIT_SCREEN_VERTICAL_SPLIT_PERCENT_" + this.key, this.verticalSplitPercent.toString());
		}
		else {
			this.leftWidth = "100%";
			this.leftHeight = "calc(" + this.horizontalSplitPercent + "% - 1px)";
			this.rightWidth = "100%";
			this.rightHeight = (100 - this.horizontalSplitPercent) + "%";
			this.splitBarMiddleTop = "calc(" + this.horizontalSplitPercent + "% - 3px)";
			this.splitBarMiddleLeft = "0";
			if (this.key)
				localStorage.setItem("SLICK_SPLIT_SCREEN_HORIZONTAL_SPLIT_PERCENT_" + this.key, this.horizontalSplitPercent.toString());
		}

		if (this.key) {
			localStorage.setItem("SLICK_SPLIT_SCREEN_ORIENTATION_" + this.key, this.currentOrientation)
		}
	}
}
