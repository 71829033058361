export interface ILathamOrderPickListLineItemModel {
    uuid: string;
    lathamOrderId: number;
    lathamOrderPickListDefinitionId: number;
    lathamOrderPickListDefinitionTreePath: string;
    lathamOrderPickListDefinitionWhy: string;
    sku: string;
    description: string;
    quantity: number;
    itemId: number;
    cost: number;
    displayOrder: number;
    itemInventoryType: number;

    childLineItems: ILathamOrderPickListLineItemModel[];

}

export class LathamOrderPickListLineItemModel implements ILathamOrderPickListLineItemModel {
    uuid: string;
    lathamOrderId: number;
    lathamOrderPickListDefinitionId: number;
    lathamOrderPickListDefinitionTreePath: string;
    lathamOrderPickListDefinitionWhy: string;
    sku: string;
    description: string;
    quantity: number;
    itemId: number;
    cost: number;
    displayOrder: number;
    itemInventoryType: number;

    childLineItems: ILathamOrderPickListLineItemModel[];
}