// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.denied-message {
    padding: 0 1em 0 1em;
    color: lightgray;
    font-style: italic;
}`, "",{"version":3,"sources":["webpack://./app-desktop/src/components/permission-denied/permission-denied.component.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":[".denied-message {\r\n    padding: 0 1em 0 1em;\r\n    color: lightgray;\r\n    font-style: italic;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
