export interface IMaintenanceReadingItemValueModel {
	maintenanceReadingItemValueId: number;
	maintenanceReadingItemId: number;
	itemValue: string;
	
}

export class MaintenanceReadingItemValueModel implements IMaintenanceReadingItemValueModel {
	maintenanceReadingItemValueId: number;
	maintenanceReadingItemId: number;
	itemValue: string;
}