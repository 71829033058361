// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.minHeight {
  min-height: calc(100vh - 180px);
  max-height: calc(100vh - 180px);
  overflow-y: auto;
}

.header {
  width: 100%;
  border-bottom: 1px solid black;
  text-align: left;
  line-height: 0.1em;
  margin: 20px 0 5px;
}`, "",{"version":3,"sources":["webpack://./app-desktop/src/app/invoices/invoice-components/invoice-edit/invoice-edit.component.scss"],"names":[],"mappings":"AAAA;EACC,+BAAA;EACA,+BAAA;EACA,gBAAA;AACD;;AAEA;EACC,WAAA;EACA,8BAAA;EACA,gBAAA;EACA,kBAAA;EACA,kBAAA;AACD","sourcesContent":[".minHeight {\r\n\tmin-height: calc(100vh - 180px);\r\n\tmax-height: calc(100vh - 180px);\r\n\toverflow-y: auto;\r\n}\r\n\r\n.header {\r\n\twidth: 100%;\r\n\tborder-bottom: 1px solid black;\r\n\ttext-align: left;\r\n\tline-height: 0.1em;\r\n\tmargin: 20px 0 5px;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
