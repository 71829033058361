import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SlickDragDropDirective } from "./slick-drag-drop.directive";
import { SlickDraggableDirective } from "./slick-draggable.directive";

@NgModule({
	imports: [CommonModule],
	declarations: [SlickDragDropDirective, SlickDraggableDirective],
	exports: [SlickDragDropDirective, SlickDraggableDirective]
})
export class SlickDragDropModule { }

export { SlickDragDropDirective }
export { SlickDraggableDirective }