import { Component, OnInit } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { GlobalsService } from "@services";

@Component({
	selector: "top-menu",
	templateUrl: "top-menu.component.html",
	styleUrls: ['top-menu.component.scss'],
	host: { 'class': 'top-menu' }

})

export class TopMenuComponent implements OnInit {
	// Make sure anything here is lower case!!!
	customerUrls: string[] = ['/customers', '/crm', '/crm/outreaches']
	jobUrls: string[] = ['/jobs', '/recurring', '/smartlist']
	inventoryUrls: string[] = ['/items', '/inventory']
	purchasingUrls: string[] = ['/vendors', '/purchaseorders', '/receiving']
	accountingUrls: string[] = ['/estimates', '/salesorders', '/invoices', '/payments', '/quickbooks']
	documentsUrls: string[] = ['/documents']
	reportingUrls: string[] = ['/reporting/apptaveragesreport', '/reporting/cscpartslistreport', '/reporting/itemusagereport', '/reporting/itemusagebybuilderreport', '/reporting/lathamorderinsights', '/reporting/lathamreport', '/reporting/lathamsystemsummaryreport', '/reporting/rebatesreport', '/reporting/resourceappttypesreport', '/reporting/ontariobuilderreport']
	settingsUrls: string[] = ['/settings', '/users', '/timeclock', '/settings/companygrid', '/settings/helppages', '/settings/versionreleases', '/settings/companyaddress', '/settings/companylogo', '/settings/companysettings', '/settings/companylocations', '/settings/openhours', '/settings/emailtemplates', '/settings/accountingTemplates', '/settings/textchattemplates', '/settings/timeclocksettings', '/settings/serviceagreement', '/settings/customeroptions', '/settings/customerareas', '/settings/customcustomerfields', '/settings/apptstatuses', '/settings/appttypes', '/settings/apptOptions', '/settings/jobtypes', '/settings/resources', '/settings/schedulingoptions', '/settings/emailOptions', '/settings/invoiceFields', '/settings/serviceareas', '/settings/smartlistquestions', '/settings/accountingoptions', '/settings/discountsrebates', '/settings/fuelsurcharge', '/settings/invoicestatuses', '/settings/laboritems', '/settings/onlinepaymentsoptions', '/settings/paymentterms', '/settings/pricelevels', '/settings/taxes', '/settings/quickadds', '/settings/itemgroupings', '/settings/inventory/settings', '/settings/inventory/locations', '/settings/lathamordering', '/settings/lathamorderingpicklistdefinitions', '/settings/quickbooks', '/settings/businesscentral', '/settings/quickbooksonline', '/settings/timeclock', '/settings/usaepay-settings']

	isCustomers: boolean;
	isJobs: boolean;
	isInventory: boolean;
	isPurchasing: boolean;
	isAccounting: boolean;
	isSettings: boolean;
	isReporting: boolean;

	canAccessSchedule: boolean = false;
	canAccessCustomers: boolean = false;
	canAccessCRM: boolean = false;
	canAccessJobs: boolean = false;
	canAccessRecurringJobs: boolean = false
	canAccessLathamOrders: boolean = false;
	canAccessPartsList: boolean = false;
	canAccessInventory: boolean = false;
	canAccessEstimates: boolean = false;
	canAccessSalesOrders: boolean = false;
	canAccessInvoices: boolean = false;
	canAccessPayments: boolean = false;
	canAccessVendors: boolean = false;
	canAccessPurchaseOrders: boolean = false;
	canAccessReceiving: boolean = false;
	canAccessDocuments: boolean = false;
	canAccessSmartlist: boolean = false;
	canAccessReports: boolean = false;
	canAccessTimeclock: boolean = false;
	canAccessSettings: boolean = false;
	canAccessQuickbooks: boolean = false;
	canAccessUsers: boolean = false;
	canAccessChat: boolean = false;

	route: string;

	isQPro = GlobalsService.company.isQPro;
	isLathamOrdersOnly = GlobalsService.company.isLathamOrdersOnly;


	constructor(private router: Router) {
		this.canAccessCustomers = GlobalsService.checkPermission("customers", "access");
		this.canAccessCRM = GlobalsService.checkPermission("crm", "access");
		this.canAccessJobs = GlobalsService.checkPermission("jobs", "access");
		this.canAccessRecurringJobs = GlobalsService.checkPermission("RecurringJobs", "access");
		this.canAccessPartsList = GlobalsService.checkPermission("parts list", "access");
		this.canAccessInventory = GlobalsService.company.useInventory && GlobalsService.checkPermission("inventory", "access");
		this.canAccessEstimates = GlobalsService.checkPermission("estimates", "access");
		this.canAccessSalesOrders = GlobalsService.checkPermission("SalesOrders", "access");
		this.canAccessInvoices = GlobalsService.checkPermission("invoices", "access");
		this.canAccessPayments = GlobalsService.checkPermission("payments", "access");
		this.canAccessVendors = GlobalsService.checkPermission("vendors", "access");
		this.canAccessPurchaseOrders = GlobalsService.checkPermission("PurchaseOrders", "access");
		this.canAccessReceiving = GlobalsService.checkPermission("receiving", "access");
		this.canAccessDocuments = GlobalsService.checkPermission("documents", "access");
		this.canAccessSmartlist = GlobalsService.checkPermission("smartlist", "access");
		this.canAccessReports = GlobalsService.checkPermission("reports", "access");
		this.canAccessTimeclock = GlobalsService.checkPermission("timeclock", "access");
		this.canAccessSettings = GlobalsService.checkPermission("settings", "access");
		this.canAccessQuickbooks = GlobalsService.company.quickbooksWebConnectorEnabled && GlobalsService.checkPermission("quickbooks", "access");
		this.canAccessUsers = GlobalsService.checkPermission("users", "access");
	}

	ngOnInit() {
		this.router.events.forEach((event) => {
			if (event instanceof NavigationStart) {
				this.route = event.url.toLowerCase();

				this.isCustomers = (this.customerUrls.findIndex(url => url === this.route) >= 0);
				this.isJobs = (this.jobUrls.findIndex(url => url === this.route) >= 0);
				this.isInventory = (this.inventoryUrls.findIndex(url => url === this.route) >= 0);
				this.isPurchasing = (this.purchasingUrls.findIndex(url => url === this.route) >= 0);
				this.isAccounting = (this.accountingUrls.findIndex(url => url === this.route) >= 0);
				this.isReporting = (this.reportingUrls.findIndex(url => url === this.route) >= 0);

				if (this.isCustomers)
					(<HTMLInputElement>document.getElementById("item-customers")).checked = true;

				if (this.isJobs)
					(<HTMLInputElement>document.getElementById("item-jobs")).checked = true;

				if (this.isInventory)
					(<HTMLInputElement>document.getElementById("item-inventory")).checked = true;

				if (this.isPurchasing)
					(<HTMLInputElement>document.getElementById("item-purchasing")).checked = true;

				if (this.isAccounting)
					(<HTMLInputElement>document.getElementById("item-accounting")).checked = true;

				if (this.isReporting)
					(<HTMLInputElement>document.getElementById("item-reports")).checked = true;

				this.isSettings = (this.settingsUrls.findIndex(url => url === this.route) >= 0);
			}
		});
	}
}