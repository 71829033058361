export interface IMistakeModel {
    mistakeId: number;
    jobId: number;
    lathamOrderId: number;
    mistakeType: string;
    description: string;
    enteredBy: string;
    enteredDate: Date;
    toDelete: boolean;
}

export class MistakeModel implements IMistakeModel {
    mistakeId: number;
    jobId: number;
    lathamOrderId: number;
    mistakeType: string;
    description: string;
    enteredBy: string;
    enteredDate: Date;
    toDelete: boolean;
}