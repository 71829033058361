import { Component, Input, ViewChild } from "@angular/core";
import { SlickDialogComponent } from "@slick-components";

@Component({
	selector: "document-viewer-dialog",
	template: `<slick-dialog key='DIALOG_DOCUMENT_VIEWER' #dialogRef [top]="50" [width]="800" header="View Document"><document-viewer [documentId]='documentId'></document-viewer></slick-dialog>`
})
export class DocumentViewerDialogComponent {

	@ViewChild("dialogRef", { static: true }) dialogRef: SlickDialogComponent;

	documentId: number;

	constructor() { }

	showDialog(documentId: number) {
		this.documentId = documentId;
		this.dialogRef.showDialog();
	}
}