import { Directive, Input, Output, EventEmitter, OnChanges, OnInit, OnDestroy, ElementRef, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { SlickSleepService } from "../utils/slick-sleep.service";
import { SlickUtilsService } from "../utils/slick-utils.service";

@Directive({
	selector: '[slick-type-ahead]',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SlickTypeAheadDirective),
			multi: true,
		}]
})
export class SlickTypeAheadDirective implements OnChanges, ControlValueAccessor {
	@Input("slick-type-ahead") slickTypeAhead: any[];
	@Input("slick-type-ahead-field-id") slickTypeAheadField: string;
	@Input("slick-type-ahead-field-ids") slickTypeAheadFields: string;
	@Output("slick-type-ahead-change") slickTypeAheadChange: EventEmitter<any> = new EventEmitter();

	private fnKeyDown = (e) => this.keyDown(e);

	private filteredItems: string[];
	private suggestedItem: string;

	constructor(private el: ElementRef) {

	}

	ngOnInit() {
		this.el.nativeElement.addEventListener('keydown', this.fnKeyDown);
	}

	ngOnChanges() {
	}

	ngOnDestroy() {
		this.el.nativeElement.removeEventListener('keydown', this.fnKeyDown);
	}

	async keyDown(e: KeyboardEvent) {
		if (e.altKey || e.ctrlKey)
			return;

		var valid =
			(e.keyCode > 47 && e.keyCode < 58) || // number keys
			e.keyCode === 32 || e.keyCode === 13 ||
			e.keyCode === 8 || e.keyCode === 9 || // spacebar, return & tab key(s) (if you want to allow carriage returns)
			(e.keyCode > 64 && e.keyCode < 91) || // letter keys
			(e.keyCode > 95 && e.keyCode < 112) || // numpad keys
			(e.keyCode > 185 && e.keyCode < 193) || // ;=,-./` (in order)
			(e.keyCode > 218 && e.keyCode < 223);   // [\]' (in order)

		if (!valid) {
			return;
		}

		e.stopPropagation();

		await SlickSleepService.sleep();
		// Tab/enter
		if (e.which === 9) {
			this.propagateChange(this.suggestedItem);
			this.createSelection(this.el.nativeElement, this.el.nativeElement.value, 0);

			if (this.slickTypeAheadChange) {
				if (this.filteredItems && this.filteredItems.length > 0)
					this.slickTypeAheadChange.emit(this.filteredItems[0])
				else
					this.slickTypeAheadChange.emit(this.el.nativeElement.value);
			}

			return false;
		}

		if (e.which === 8) {
			if (this.el.nativeElement.value.length > 0) {
				var cursorLoc = this.el.nativeElement.selectionStart;
				if (cursorLoc > 0) {
					var valSubstr = this.el.nativeElement.value.substring(0, cursorLoc - 1);
					valSubstr += this.el.nativeElement.value.substring(cursorLoc);
					this.el.nativeElement.value = valSubstr;
				}
			}
		}

		if (!this.el.nativeElement.value)
			return;

		// Esc
		if (e.which === 27) {
			this.filteredItems = null;
			this.suggestedItem = null;

			return false;
		}

		this.suggestedItem = null;
		let val = this.el.nativeElement.value.toLowerCase();
		
		if (!this.slickTypeAheadField && !this.slickTypeAheadFields) {
			this.filteredItems = this.slickTypeAhead.filter(x => x.toLowerCase().indexOf(val) === 0);

			if (this.filteredItems && this.filteredItems.length > 0)
				this.suggestedItem = this.filteredItems[0];
		}
		else {
			if (this.slickTypeAheadField) {
				this.filteredItems = this.slickTypeAhead.filter(x => ((SlickUtilsService.getDeepObject(x, this.slickTypeAheadField)) ? SlickUtilsService.getDeepObject(x, this.slickTypeAheadField).toLowerCase().indexOf(val) === 0 : false))

				if (this.filteredItems && this.filteredItems.length > 0)
					this.suggestedItem = SlickUtilsService.getDeepObject(this.filteredItems[0], this.slickTypeAheadField)
			}
			else {
				let fields = this.slickTypeAheadFields.split("|");
				this.filteredItems = null;
				fields.forEach(field => {
					if (!this.filteredItems || this.filteredItems.length === 0) {
						this.filteredItems = this.slickTypeAhead.filter(x => ((SlickUtilsService.getDeepObject(x, field)) ? SlickUtilsService.getDeepObject(x, field).toLowerCase().indexOf(val) === 0 : false))
						if (this.filteredItems && this.filteredItems.length > 0) {
							this.suggestedItem = SlickUtilsService.getDeepObject(this.filteredItems[0], field);
						}
					}
				});
			}
		}

		if (this.suggestedItem) {
			var len = this.el.nativeElement.value.length;
			this.el.nativeElement.value = this.suggestedItem;
			this.createSelection(this.el.nativeElement, len, this.suggestedItem.length)
		}
		else {
			this.suggestedItem = this.el.nativeElement.value;
		}
	}

	private createSelection(field: any, start: number, end: number) {
		if (field.createTextRange) {
			var selRange = field.createTextRange();
			selRange.collapse(true);
			selRange.moveStart('character', start);
			selRange.moveEnd('character', end);
			selRange.select();
			field.focus();
		} else if (field.setSelectionRange) {
			field.focus();
			field.setSelectionRange(start, end);
		} else if (typeof field.selectionStart != 'undefined') {
			field.selectionStart = start;
			field.selectionEnd = end;
			field.focus();
		}
	}

	propagateChange = (_: any) => { };

	// this is the initial value set to the component
	public writeValue(obj: any) {
	}

	// registers 'fn' that will be fired when changes are made
	// this is how we emit the changes back to the form
	public registerOnChange(fn: any) {
		this.propagateChange = fn;
	}

	// not used, used for touch input
	public registerOnTouched() { }

}
