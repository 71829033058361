import { AppointmentModel } from "./appointment.model";
import { ScheduledAppointmentModel } from "./scheduled-appointment.model";

export class OfflineAppointmentUpdateModel implements IOfflineAppointmentUpdateModel {
    appointmentModel: AppointmentModel;
    scheduledAppointmentModel: ScheduledAppointmentModel;
}

export interface IOfflineAppointmentUpdateModel {
    appointmentModel: AppointmentModel;
    scheduledAppointmentModel: ScheduledAppointmentModel;

}
