export interface IConflictingAppointment {
    scheduledDateTime: Date;
    appointmentId: number;
    appointmentStatusId: number;
    resourceId: number;
    travelMinutes: number;
    scheduledMinutes: number;
}

export class ConflictingAppointment implements IConflictingAppointment {
    scheduledDateTime: Date;
    appointmentId: number;
    appointmentStatusId: number;
    resourceId: number;
    travelMinutes: number;
    scheduledMinutes: number;
}