// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  padding: 10px;
}

.credit-card-info {
  padding-bottom: 20px;
}

.card-ui {
  min-height: 140px;
  position: relative;
  margin: 0 auto;
  width: 275px;
  outline: none;
  display: inline-block;
}

.card-ui:hover, .card-ui.add-new-payment-holder:hover {
  cursor: pointer;
}

.card-ui .add-new-payment {
  line-height: 118px;
  text-align: center;
}

.card-ui .card-number {
  line-height: 70px;
  letter-spacing: 2px;
  font-weight: bold;
}

.card-ui > div {
  line-height: 20px;
}

.card-description, .card-icon {
  display: inline-block;
}

.default-card {
  left: 0px;
  position: absolute;
  bottom: 0px;
  font-size: 10px;
  font-weight: bold;
}

.btn-defaultCard {
  background-color: rgba(var(--bs-primary-rgb), 0.08);
  border-bottom: 2px solid var(--bs-btn-hover-bg);
}

.btn-defaultCard:hover {
  background-color: var(--bs-btn-hover-bg) !important;
}`, "",{"version":3,"sources":["webpack://./app-desktop/src/app/customers/customer-components/customer-edit/card/card-edit.component.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;AADJ;;AAIA;EACI,oBAAA;AADJ;;AAIA;EACI,iBAAA;EACA,kBAAA;EACA,cAAA;EACA,YAAA;EACA,aAAA;EACA,qBAAA;AADJ;;AAII;EACI,eAAA;AADR;;AAIQ;EACI,kBAAA;EACA,kBAAA;AADZ;;AAIQ;EACI,iBAAA;EACA,mBAAA;EACA,iBAAA;AADZ;;AAIQ;EACI,iBAAA;AADZ;;AAIA;EACI,qBAAA;AADJ;;AAIA;EACI,SAAA;EACA,kBAAA;EACA,WAAA;EACA,eAAA;EACA,iBAAA;AADJ;;AAIA;EACI,mDAAA;EACA,+CAAA;AADJ;;AAIA;EACI,mDAAA;AADJ","sourcesContent":["\r\n \r\n.container {\r\n    padding: 10px;\r\n}\r\n\r\n.credit-card-info {\r\n    padding-bottom: 20px;\r\n}\r\n\r\n.card-ui {\r\n    min-height: 140px;\r\n    position: relative;\r\n    margin: 0 auto;\r\n    width: 275px;\r\n    outline: none;\r\n    display: inline-block;\r\n}\r\n\r\n    .card-ui:hover, .card-ui.add-new-payment-holder:hover {\r\n        cursor: pointer;\r\n    }\r\n\r\n        .card-ui .add-new-payment {\r\n            line-height: 118px;\r\n            text-align: center;\r\n        }\r\n\r\n        .card-ui .card-number {\r\n            line-height: 70px;\r\n            letter-spacing: 2px;\r\n            font-weight: bold;\r\n        }\r\n\r\n        .card-ui > div {\r\n            line-height: 20px;\r\n        }\r\n\r\n.card-description, .card-icon {\r\n    display: inline-block;\r\n}\r\n\r\n.default-card {\r\n    left: 0px;\r\n    position: absolute;\r\n    bottom: 0px;\r\n    font-size: 10px;\r\n    font-weight: bold;\r\n}\r\n\r\n.btn-defaultCard{\r\n    background-color: (rgba(var(--bs-primary-rgb), 0.08));\r\n    border-bottom: 2px solid var(--bs-btn-hover-bg);\r\n}\r\n\r\n.btn-defaultCard:hover{\r\n    background-color: var(--bs-btn-hover-bg) !important;\r\n} \r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
