import { Injectable } from '@angular/core';
import { HttpService } from "./utils/http.service";
import { IAppointmentStatusModel, IAppointmentModel } from '@models';

@Injectable()
export class ServiceTechAppointmentsService {
	constructor(private httpService: HttpService) { }

	async setStatus(appointmentId: number, appointmentStatusDescription: string, notes: string, customerNotes: string): Promise<IAppointmentStatusModel> {
		try {
			var params = {
				appointmentId,
				appointmentStatusDescription,
				notes,
				customerNotes
			}

			return this.httpService.post("/appointments/setappointmentstatus", params)
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async sendInTransitSms(appointmentModel: IAppointmentModel, ): Promise<IAppointmentStatusModel> {
		try {
			return this.httpService.post("/sms/sendInTransitSms", { appointment: appointmentModel })
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async SendAtJobsiteSms(appointmentModel: IAppointmentModel, ): Promise<IAppointmentStatusModel> {
		try {
			return this.httpService.post("/sms/sendAtJobsiteSms", { appointment: appointmentModel })
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async sendNotFinishedSms(appointmentModel: IAppointmentModel, ): Promise<IAppointmentStatusModel> {
		try {
			return this.httpService.post("/sms/sendNotFinishedSms", { appointment: appointmentModel })
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async sendCompletedSms(appointmentModel: IAppointmentModel, ): Promise<IAppointmentStatusModel> {
		try {
			return this.httpService.post("/sms/sendCompletedSms", { appointment: appointmentModel })
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async saveNotes(appointmentId: number, notes: string, customerNotes: string): Promise<boolean> {
		try {
			var params = {
				appointmentId,
				notes,
				customerNotes
			}

			return this.httpService.post("/appointments/appointmentNotesUpdate", params)
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

}
