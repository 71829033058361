import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OfflineStore } from "@stores";
import { ICompanyModel, IUserInfoModel, IUserPermissionModel, IInitBadgeDisplayModel, ITimeclockEntryModel } from '@models';

export interface IInitModel {
	companyModel: ICompanyModel;
	userInfoModel: IUserInfoModel;
	lastTimeclockModel: ITimeclockEntryModel;
	initialBadgeDisplayModel: IInitBadgeDisplayModel;
}

@Injectable()
export class GlobalsService {
	public static isOnline: boolean;
	public static isDebug: boolean = false;
	public static isAndroid: boolean;
	public static isiOS: boolean;
	public static isDesktop: boolean;
	public static isStaging: boolean = false;
	public static isAppMobile: boolean = false;
	public static isRC: boolean = false;
	public static pushNotificationToken: string;
	public static isLoggedIn: boolean;
	public static isQuickbooksEnabled: boolean;
	public static isBusinessCentralEnabled: boolean;
	public static isCreditCardBillingEnabled: boolean;
	public static isOnlinePayments: boolean;
	public static jwtToken: string = localStorage.getItem('skedit_jwt_token') || sessionStorage.getItem('skedit_jwt_token');
	public static appVersion = "4.3.11";
	public static databaseVersion = 1;
	public static isSettingsOpen: boolean = false;

	public static get hostName(): string {
		if (GlobalsService.isStaging)
			return 'https://staging.skedit.com';
		else if (GlobalsService.isStaging)
			return 'https://rc.skedit.com';
		else if (GlobalsService.isDebug)
			return 'http://dev.skedit.com'
		else
			return 'https://app.skedit.com'
	}

	public static get apiUrl(): string {
		if (GlobalsService.isStaging)
			return 'https://stagingapi.skedit.com';
		else if (GlobalsService.isRC)
			return 'https://rcapi.skedit.com';
		else if (GlobalsService.isDebug)
			return 'http://devapi.skedit.com'
		//return 'http://localhost:44315'
		else
			return 'https://api.skedit.com'
	}

	public static company: ICompanyModel;
	public static userInfo: IUserInfoModel;
	public static initialBadgeDisplayModel: IInitBadgeDisplayModel;
	public static lastTimeclockModel: ITimeclockEntryModel;

	constructor(private http: HttpClient,
		private offlineStore: OfflineStore) {
	}

	setDebug() {
		GlobalsService.isDebug = true;
	}

	setStaging() {
		GlobalsService.isStaging = true;
	}

	setReleaseCandidate() {
		GlobalsService.isRC = true;
	}

	setAppMobile() {
		GlobalsService.isAppMobile = true;
	}

	static getPlatform(): string {
		if (GlobalsService.isAndroid)
			return "Android";
		else if (GlobalsService.isiOS)
			return "iOS";
		else
			return "Web";
	}

	async isLoggedIn(): Promise<any> {
		if (GlobalsService.isOnline === false) {
			if (GlobalsService.jwtToken)
				return true;
			else
				return false;
		}

		try {
			if (window.location.href.toLowerCase().indexOf('/login') >= 0) {
				GlobalsService.isLoggedIn = false;
				return Promise.resolve(false);
			}

			GlobalsService.isLoggedIn = false;
			if (GlobalsService.jwtToken) {
				console.log("before init validate")
				GlobalsService.isLoggedIn = <boolean>(await this.http.get(`/init/validateLogin?jwtToken=${GlobalsService.jwtToken}`).toPromise());
				console.log("after", GlobalsService.isLoggedIn)
				if (GlobalsService.isLoggedIn == false) {
					console.info('Clearing Token');
					localStorage.removeItem('skedit_jwt_token');
					window.location.href = '/';
				}
			}

			return GlobalsService.isLoggedIn;
		} catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async initDesktop(): Promise<any> {
		try {
			const initModel = <IInitModel>(
				await this.http.get(`/init/init?platform=${GlobalsService.getPlatform()}&version=${GlobalsService.appVersion}`).toPromise()
			);
			this.init_internal(initModel);
        } catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async initMobile(): Promise<void> {
		try {
			let initModel: IInitModel;

			initModel = await this.offlineStore.getGlobalSettings();
			this.init_internal(initModel);

		} catch (error) {
			console.error(error);
		}
	}

	private init_internal(initModel: IInitModel) {
		GlobalsService.lastTimeclockModel = initModel.lastTimeclockModel;
		GlobalsService.userInfo = initModel.userInfoModel;
		GlobalsService.company = initModel.companyModel;
		GlobalsService.initialBadgeDisplayModel = initModel.initialBadgeDisplayModel;
		GlobalsService.isQuickbooksEnabled =
			GlobalsService.company.quickbooksOnlineEnabled ||
			GlobalsService.company.quickbooksWebConnectorEnabled;
		GlobalsService.isBusinessCentralEnabled = GlobalsService.company.businessCentralEnabled;
		GlobalsService.isCreditCardBillingEnabled =
			GlobalsService.company.useBambora || GlobalsService.company.usePayTrace ||
			GlobalsService.company.useUSAePay || GlobalsService.company.useZift;

	}

	public static checkPermission(feature: string, actions: string): boolean {
		if ((GlobalsService.userInfo?.userPermissions ?? []).length === 0)
			return false;

		const permissionFeature: IUserPermissionModel[] = GlobalsService.userInfo.userPermissions.filter(p => p.feature.toLocaleLowerCase() === feature.toLocaleLowerCase());
		let granted = true;

		const actionsArray = actions.split('|');
		actionsArray.forEach(a => {
			const featureAction: IUserPermissionModel = permissionFeature.find(
				f => {
					return (
						f.action.toLocaleLowerCase() === a.toLocaleLowerCase()
					);
				}
			);
			if (!featureAction) {
				console.error(
					'Feature: ' +
					feature +
					' | Action: ' +
					a +
					' was not found.'
				);
				return false;
			}

			if (featureAction.granted === false) granted = false;
		});

		return granted;
	}

	static getLayout(layoutSetting: string): string {
		const viewType = layoutSetting || 'grid-split-screen';
		if (layoutSetting !== 'grid-split-screen' && layoutSetting !== 'grid-full-screen' && layoutSetting !== 'card')
			return "grid-split-screen";

		return viewType;

	}
}
