import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { UtilsService } from "./utils.service";
import { NavController } from '@ionic/angular';

class PageDetail {
	uuid: string;
	route: string;
	returnRoute: string;
	data: any;
	resolve: any;
}

@Injectable()
export class NavPageService {
	private static _PAGE_DETAILS: PageDetail[] = [];

	constructor(private readonly router: Router,
		private readonly navCtrl: NavController) { }

	push(route, data = null): Promise<any> {
		return new Promise((resolve) => {
			const pageDetail = new PageDetail();
			pageDetail.uuid = UtilsService.newGuid();
			pageDetail.route = route;
			pageDetail.returnRoute = this.router.url;
			pageDetail.data = data;
			pageDetail.resolve = resolve;

			NavPageService._PAGE_DETAILS.unshift(pageDetail);

			this.router.navigate([route]);
		});
	}

	pop(returnData: any = null, defaultRoute: string = null) {
		if (!NavPageService._PAGE_DETAILS || NavPageService._PAGE_DETAILS.length === 0) {
			this.navCtrl.navigateRoot("/");
			return;
		}

		NavPageService._PAGE_DETAILS[0].resolve(returnData);
		const returnRoute = NavPageService._PAGE_DETAILS[0].returnRoute;

		NavPageService._PAGE_DETAILS = NavPageService._PAGE_DETAILS.slice(1);

		if (defaultRoute)
			this.navCtrl.navigateRoot(defaultRoute);
		else
			this.navCtrl.navigateBack(returnRoute);
	}

	getData(): any {
		if (!NavPageService._PAGE_DETAILS || NavPageService._PAGE_DETAILS.length === 0)
			return null;
		else
			return NavPageService._PAGE_DETAILS[0].data;
	}
}
