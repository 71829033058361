// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn {
  margin: 0;
  border-radius: 0;
  width: 120px;
}

.transition {
  position: relative;
  animation: submenuIn 0.5s forwards;
  transition: ease-in-out;
  animation-fill-mode: backwards;
}

.quickbooks {
  position: relative;
}

.quickbooks .quickbooks-icon {
  font-family: icons;
  font-size: 1.4rem;
  line-height: 12px;
  min-height: 15px;
  padding-top: 2px;
  display: block;
}

.quickbooks .quickbooks-text {
  margin-left: 15px;
  line-height: 22px;
}

@supports (-moz-appearance: none) {
  .side-menu .quickbooks .quickbooks-text {
    line-height: 22px;
  }
}
@keyframes submenuIn {
  0% {
    left: -100px;
    top: 0px;
    opacity: 0;
  }
  40% {
    left: 30px;
    opacity: 0.6;
  }
  100% {
    left: 0px;
    top: 0px;
    opacity: 1;
  }
}`, "",{"version":3,"sources":["webpack://./app-desktop/src/app/layout/top-menu/top-menu.component.scss"],"names":[],"mappings":"AAAA;EACC,SAAA;EACA,gBAAA;EACA,YAAA;AACD;;AAEA;EACC,kBAAA;EACA,kCAAA;EACA,uBAAA;EACA,8BAAA;AACD;;AAEA;EACC,kBAAA;AACD;;AAEA;EACC,kBAAA;EACA,iBAAA;EACA,iBAAA;EACA,gBAAA;EACA,gBAAA;EACA,cAAA;AACD;;AAEA;EACC,iBAAA;EACA,iBAAA;AACD;;AAEA;EACC;IACC,iBAAA;EACA;AACF;AACA;EACC;IACC,YAAA;IACA,QAAA;IACA,UAAA;EACA;EACD;IACC,UAAA;IACA,YAAA;EACA;EACD;IACC,SAAA;IACA,QAAA;IACA,UAAA;EACA;AACF","sourcesContent":[".btn {\r\n\tmargin: 0;\r\n\tborder-radius: 0;\r\n\twidth: 120px\r\n}\r\n\r\n.transition {\r\n\tposition: relative;\r\n\tanimation: submenuIn .5s forwards;\r\n\ttransition: ease-in-out;\r\n\tanimation-fill-mode: backwards;\r\n}\r\n\r\n.quickbooks {\r\n\tposition: relative;\r\n}\r\n\r\n.quickbooks .quickbooks-icon {\r\n\tfont-family: icons;\r\n\tfont-size: 1.4rem;\r\n\tline-height: 12px;\r\n\tmin-height: 15px;\r\n\tpadding-top: 2px;\r\n\tdisplay: block\r\n}\r\n\r\n.quickbooks .quickbooks-text {\r\n\tmargin-left: 15px;\r\n\tline-height: 22px;\r\n}\r\n\r\n@supports (-moz-appearance:none) {\r\n\t.side-menu .quickbooks .quickbooks-text {\r\n\t\tline-height: 22px;\r\n\t}\r\n}\r\n@keyframes submenuIn {\r\n\t0% {\r\n\t\tleft: -100px;\r\n\t\ttop: 0px;\r\n\t\topacity: 0;\r\n\t}\r\n\t40% {\r\n\t\tleft: 30px;\r\n\t\topacity: .6;\r\n\t}\r\n\t100% {\r\n\t\tleft: 0px;\r\n\t\ttop: 0px;\r\n\t\topacity:1;\r\n\t}\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
