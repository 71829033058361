// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customer-info {
  display: flex;
  justify-content: space-between;
}
.customer-info .row-elements {
  margin-right: 1vw;
}
.customer-info .row-elements :last-child {
  margin-right: 0px;
}

label {
  padding-top: 7px;
  font-weight: 500;
}

.checkbox-item {
  display: flex;
}
.checkbox-item input {
  margin-right: 5px;
}

.customer-list-item {
  line-height: 1.1em;
  font-size: 12px;
  list-style: none;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding: 0px;
  margin: 0px;
}

.customer-list-item li.bigger {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 3px;
}`, "",{"version":3,"sources":["webpack://./app-desktop/src/app/customers/customer-components/customer-edit/customer-edit-layout/customer-edit-layout.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;AACJ;AAEI;EACI,iBAAA;AAAR;AACQ;EACI,iBAAA;AACZ;;AAIA;EACI,gBAAA;EAEA,gBAAA;AAFJ;;AAKA;EACI,aAAA;AAFJ;AAGI;EACI,iBAAA;AADR;;AAKA;EACI,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,mBAAA;EACA,iBAAA;EACA,YAAA;EACA,WAAA;AAFJ;;AAKA;EACI,iBAAA;EACA,eAAA;EACA,kBAAA;AAFJ","sourcesContent":[".customer-info {\r\n    display:flex;\r\n    justify-content: space-between;\r\n    // padding-top:8px;\r\n\r\n    .row-elements {\r\n        margin-right: 1vw;\r\n        :last-child {\r\n            margin-right: 0px;\r\n        }\r\n    }\r\n}\r\n\r\nlabel {\r\n    padding-top: 7px;\r\n    // padding-top: 10px;\r\n    font-weight: 500;\r\n  }\r\n  \r\n.checkbox-item {\r\n    display: flex;\r\n    input {\r\n        margin-right: 5px;\r\n    }\r\n}\r\n\r\n.customer-list-item {\r\n    line-height: 1.1em;\r\n    font-size: 12px;\r\n    list-style: none;\r\n    padding-top: 5px;\r\n    padding-bottom: 5px;\r\n    padding-left: 5px;\r\n    padding: 0px;\r\n    margin: 0px;\r\n}\r\n\r\n.customer-list-item li.bigger {\r\n    font-weight: bold;\r\n    font-size: 14px;\r\n    margin-bottom: 3px;\r\n}\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
