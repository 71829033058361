import { Component, Input, Output, forwardRef, ViewChild, ElementRef, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { SlickSleepService } from '../utils/slick-sleep.service';

class Star {
    constructor(selected: boolean) {
        this.selected = selected;
    }

    selected: boolean;
}

@Component({
    selector: 'slick-star-rating',
    templateUrl: 'slick-star-rating.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SlickStarRatingComponent),
            multi: true,
        }]
})

export class SlickStarRatingComponent implements OnChanges {
    protected stars: Star[] = [];
    private ratingValue: number;

    @Input() checkedColor: string = "gold";
    @Input() uncheckedColor: string = "gray";
    @Input() size: string = "24px";
    @Input() readonly: boolean = false;
    @Input() totalStars: number = 5;

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges) {

        this.readonly = ((this.readonly || 'false').toString() === 'true')
        
        if (changes.totalstars)
            this.generateStars();
    }

    propagateChange = (_: any) => { };

    // this is the initial value set to the component
    writeValue(obj: number) {
        if (obj) 
            this.ratingValue = obj - 1;
        else 
            this.ratingValue = -1;

        this.generateStars();
    }

    // registers 'fn' that will be fired when changes are made
    // this is how we emit the changes back to the form
    registerOnChange(fn: any) {
        this.propagateChange = fn;
    }

    // not used, used for touch input
    registerOnTouched() { }

    reset() {
        this.stars.forEach((s, idx) => s.selected = (idx <= this.ratingValue));  
    }

    onMouseEnter(starIdx: number) {
        if (this.readonly === true)
            return;

        this.stars.forEach((s, idx) => s.selected = (idx <= starIdx));        
    }

    setRatingValue(starIdx: number) {
        if (this.readonly === true)
            return;

        this.ratingValue = starIdx;
        this.propagateChange(this.ratingValue + 1);

        this.stars.forEach((s, idx) => s.selected = (idx <= starIdx));        
    }

    private generateStars() {
        this.stars = [];

        for (let i = 0; i < this.totalStars; i++) {
            const selected = (i <= this.ratingValue);
            this.stars.push(new Star(selected))
        }
    }
}
