import { Component, ViewChild, Output, EventEmitter } from "@angular/core";
import { SlickDialogComponent } from "@slick-components";
import { ICustomerContactModel } from "@models";

export class ContactEditContext {
    contact: ICustomerContactModel;
    index: number;
}

@Component({
    selector: "contact-edit-dialog",
    templateUrl: "contact-edit-dialog.component.html",
})
export class ContactEditDialogComponent {
    @Output() contactChange = new EventEmitter<ContactEditContext>();
    @ViewChild("contactEditDialogRef", { static: true }) contactEditDialog: SlickDialogComponent;
    contactContext = new ContactEditContext();

	constructor() {	}

    openDialog(contactContext: ContactEditContext) {
        this.contactContext = contactContext;
        this.contactEditDialog.showDialog();
    }

    republishContactChange(contact: ICustomerContactModel) {
        if (contact) {
            this.contactContext.contact = contact;
            this.contactChange.emit(this.contactContext);
        }
        this.contactEditDialog.hideDialog();
    }
}