export interface IRecurringBillingHistoryModel {
    recurringBillingHistoryId: number;
    recurringBillingId: number;
    invoiceId: number;
    paymentId: number;
    creditCardTransactionLogId: number;
    invoiceNumber: string;
    purchaseOrderNumber: string;
    invoiceCreatedDate: Date;
    billingHistoryCreatedDate: Date;
    amount: number;
    cardNumber: string;
    refNumber: string;
    status: string;
    statusError: string;
    canRetryPayment: boolean;

}

export class RecurringBillingHistoryModel implements IRecurringBillingHistoryModel {
    recurringBillingHistoryId: number;
    recurringBillingId: number;
    invoiceId: number;
    paymentId: number;
    creditCardTransactionLogId: number;
    invoiceNumber: string;
    purchaseOrderNumber: string;
    invoiceCreatedDate: Date;
    billingHistoryCreatedDate: Date;
    amount: number;
    cardNumber: string;
    refNumber: string;
    status: string;
    statusError: string;
    canRetryPayment: boolean;
}