import { Injectable } from "@angular/core";

@Injectable()
export class SlickProgressBarService {
	private isVisible: boolean = false;
	private progressPercent: number = 0;
	private progressBarTextDiv: HTMLDivElement;
	private progressBarDiv: HTMLDivElement;

	setProgress(progressPercent: number, message: string) {
		if (progressPercent < 1)
			progressPercent *= 100;
		this.progressPercent = progressPercent;

		this.progressBarDiv.style.width = (progressPercent + "%");
		this.progressBarTextDiv.innerHTML = message;
	}

	show(initialPercent: number = 0) {
		this.progressPercent = initialPercent;

		this.isVisible = false;
		let screenBlockerDiv: HTMLDivElement = (<HTMLDivElement>document.getElementById("slick-progress-bar-screen-blocker"));
		if (!screenBlockerDiv) {
			screenBlockerDiv = document.createElement("div");
			screenBlockerDiv.id = "slick-progress-bar-screen-blocker";
			screenBlockerDiv.classList.add('slick-progress-bar-screen-blocker');

			document.body.appendChild(screenBlockerDiv);
		}

		const progressDiv = document.createElement("div");
		progressDiv.classList.add('progress');
		progressDiv.classList.add('slick-progress-bar');
		progressDiv.classList.add('bg-dark');
		
		this.progressBarDiv = document.createElement("div");
		this.progressBarDiv.classList.add('progress-bar');
		this.progressBarDiv.classList.add('bg-primary');
		this.progressBarDiv.style.width = "0";
		progressDiv.append(this.progressBarDiv);

		this.progressBarTextDiv = document.createElement("div");
		this.progressBarTextDiv.classList.add('progress-bar-text');
		this.progressBarTextDiv.classList.add('text-white');
		progressDiv.append(this.progressBarTextDiv);

		screenBlockerDiv.append(progressDiv);

		screenBlockerDiv.classList.add('visible');
	}

	hide() {
		this.isVisible = false;

		const screenBlockerDiv: HTMLDivElement = (<HTMLDivElement>document.getElementById("slick-progress-bar-screen-blocker"));
		if (screenBlockerDiv)
			screenBlockerDiv.remove();
	}

}