export interface INotificationModel {
	notificationId: number;
	userId: number;
	message: string;
	notificationType: string;
	notificationDate: Date;
	referenceId: number;
	isQuickbooks: boolean;
}

export class NotificationModel implements INotificationModel {
	notificationId: number;
	userId: number;
	message: string;
	notificationType: string;
	notificationDate: Date;
	referenceId: number;
	isQuickbooks: boolean;
}