import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, OnChanges, SimpleChanges } from "@angular/core";
import { SlickUtilsService } from "../utils/slick-utils.service";

@Component({
	selector: 'slick-ordered-list',
	templateUrl: 'slick-ordered-list.component.html'
})
export class SlickOrderedListComponent implements OnInit, OnDestroy, OnChanges {
	@Input() items: any[];
	@Output() itemsChange: EventEmitter<any[]> = new EventEmitter();
	@Input() title: string;
	@Output() selectedItemsChange: EventEmitter<any[]> = new EventEmitter();
	@Input() sortOrderFieldName: string;
	@Input() displayFieldName: string;
	@Input() buttonAlignment: string = "left";

	uuid: string;
	internalItems: any[] = [];
	anySelected: boolean = false;

	fnDocumentClick = (e) => this.documentClick(e);

	constructor() {
		this.uuid = SlickUtilsService.newGuid();
	}

	ngOnInit() {
		document.addEventListener("click", this.fnDocumentClick, true);	
	}

	ngOnDestroy() {
		document.removeEventListener("click", this.fnDocumentClick, true);	
	}

	documentClick(e: MouseEvent) {
		if (e.target && SlickUtilsService.checkParentIdExists(<HTMLElement>e.target, "slick-ordered-list_" + this.uuid))
			return;

		// If we're using this in the picklist, don't do anything
		if (e.target && SlickUtilsService.checkParentClassExists(<HTMLElement>e.target, "slick-pick-list_buttons")) 
			return;

		this.internalItems.forEach(item => item.selected = false);		
		this.anySelected = false;
		if (this.selectedItemsChange)
			this.selectedItemsChange.emit(null);

	}

	async ngOnChanges(changes: SimpleChanges) {
		if (!this.items || !this.sortOrderFieldName || !this.displayFieldName)
			return;

		if (changes.items) {
			this.internalItems = [];
			
			this.sortList();

			let sortOrder = 0;
			this.items.forEach(item => {
				if (!item.uuid)
					item.uuid = SlickUtilsService.newGuid();

				if (item)
					SlickUtilsService.setDeepObject(item, this.sortOrderFieldName, sortOrder);

				this.internalItems.push({
					uuid: item.uuid,
					sortOrder: sortOrder,
					selected: false,
					text: SlickUtilsService.getDeepObject(item, this.displayFieldName)
				});

				sortOrder++;
			});

			this.anySelected = false;
		}
	}

	selectItem(e: MouseEvent, item: any) {
		if (e.ctrlKey) {
			item.selected = !item.selected;
		}
		else if (e.shiftKey) {
			let selectedItemIdx = this.internalItems.findIndex(internalItem => item.uuid === internalItem.uuid);
			this.internalItems[selectedItemIdx].selected = !this.internalItems[selectedItemIdx].selected;

			// Check to see if there is a selected item previously in the list.  This will be the default
			// For example, if the 2nd item has been selected, then they shift-select the 5th item, iterate backwards through
			// the list until we find the 2nd item, which is flagged as selected.  Then mark everything from the 2nd-5th as selected.
			let prevSelectedIndex = selectedItemIdx;
			do {
				prevSelectedIndex--;
			} while (prevSelectedIndex >= 0 && this.internalItems[prevSelectedIndex].selected === false);
			
			if (prevSelectedIndex >= 0) {
				for (let i = prevSelectedIndex; i <= selectedItemIdx; i++) 
					this.internalItems[i].selected = true;
			} else {
				// Check to see if we're going bottom->top select
				// For example, if the 5th item has been selected, then they shift-select the 2nd item, iterate forwards through
				// the list until we find the 5th item, which is flagged as selected.  Then mark everything from the 2nd-5th as selected.
				let nextSelectedIndex = selectedItemIdx;
				do {
					nextSelectedIndex++;
				} while (nextSelectedIndex <= this.internalItems.length && this.internalItems[nextSelectedIndex].selected === false);

				if (nextSelectedIndex <= this.internalItems.length) {
					for (let i = selectedItemIdx; i <= nextSelectedIndex; i++) 
						this.internalItems[i].selected = true;
				} 
			}
		}
		else {
			this.internalItems.forEach(item => item.selected = false);
			item.selected = true;
		}

		this.anySelected = false;
		let selectedItems = [];
		this.internalItems.forEach(item => {
			if (item.selected === true)
				selectedItems.push(item);
			this.anySelected = (this.anySelected === true || item.selected === true);
		});

		if (this.selectedItemsChange)
			this.selectedItemsChange.emit((selectedItems.length > 0) ? selectedItems : null);
	}

	moveTop() {
		let uuidsToMove = this.internalItems.filter(item => item.selected === true).map(item => item.uuid);
		let topArray: any[] = [];
		uuidsToMove.forEach((uuid, idx) => {
			let itemIdx = this.internalItems.findIndex(item => item.uuid === uuid);
			let temp = this.internalItems.splice(itemIdx, 1);
			topArray.push(temp[0]);
		});

		this.internalItems = topArray.concat(this.internalItems);
		this.internalItems.forEach((internalItem, idx) => {
			internalItem.sortOrder = idx;
			let item = this.items.find(item => item.uuid === internalItem.uuid);
			SlickUtilsService.setDeepObject(item, this.sortOrderFieldName, idx);
		})

		this.sortList();
	}

	moveUp() {
		let newArray = [];
		this.internalItems.forEach(x => x.sortOrder *= 10);
		this.internalItems.forEach((internalItem, idx) => {
			if (internalItem.selected === true)
				internalItem.sortOrder -= 11;
		});

		this.internalItems = this.internalItems.sort((a, b) => (a.sortOrder > b.sortOrder) ? 1 : (a.sortOrder < b.sortOrder) ? -1 : 0);

		this.internalItems.forEach((internalItem, idx) => {
			internalItem.sortOrder = idx;
			let item = this.items.find(item => item.uuid === internalItem.uuid);
			SlickUtilsService.setDeepObject(item, this.sortOrderFieldName, idx);
		})		

		this.sortList();
	}

	moveDown() {
		let newArray = [];
		this.internalItems.forEach(x => x.sortOrder *= 10);
		this.internalItems.forEach((internalItem, idx) => {
			if (internalItem.selected === true)
				internalItem.sortOrder += 11;
		});

		this.internalItems = this.internalItems.sort((a, b) => (a.sortOrder > b.sortOrder) ? 1 : (a.sortOrder < b.sortOrder) ? -1 : 0);

		this.internalItems.forEach((internalItem, idx) => {
			internalItem.sortOrder = idx;
			let item = this.items.find(item => item.uuid === internalItem.uuid);
			SlickUtilsService.setDeepObject(item, this.sortOrderFieldName, idx);
		})

		this.sortList();
	}

	moveBottom() {
		let uuidsToMove = this.internalItems.filter(item => item.selected === true).map(item => item.uuid);
		let bottomArray: any[] = [];
		uuidsToMove.forEach((uuid, idx) => {
			let itemIdx = this.internalItems.findIndex(item => item.uuid === uuid);
			let temp = this.internalItems.splice(itemIdx, 1);
			bottomArray.push(temp[0]);
		});

		this.internalItems = this.internalItems.concat(bottomArray);
		this.internalItems.forEach((internalItem, idx) => {
			internalItem.sortOrder = idx;
			let item = this.items.find(item => item.uuid === internalItem.uuid);
			SlickUtilsService.setDeepObject(item, this.sortOrderFieldName, idx);
		})

		this.sortList();
	}

	sortList() {
		if (this.items) {
			this.items = this.items.sort((a: any, b: any) => {
				let val1 = SlickUtilsService.getDeepObject(a, this.sortOrderFieldName);
				let val2 = SlickUtilsService.getDeepObject(b, this.sortOrderFieldName);

				if (val1 < val2)
					return -1;
				else if (val1 > val2)
					return 1;
				else
					return 0;			
			});
		}

		this.items.forEach((item, idx) => SlickUtilsService.setDeepObject(item, this.sortOrderFieldName, idx));
		this.itemsChange.emit(this.items);
	}
}