export interface IInvoiceLaborItemModel {
	invoiceLaborItemId: number;
	sku: string;
	description: string;
	price: number;
	quickbooksId: string;
	businessCentralId?: string;
}

export class InvoiceLaborItemModel implements IInvoiceLaborItemModel {
	constructor() {
		this.invoiceLaborItemId = 0;
	}

	invoiceLaborItemId: number;
	sku: string;
	description: string;
	price: number;
	quickbooksId: string;
	businessCentralId?: string;
}