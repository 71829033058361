import { Component, Input, OnChanges } from "@angular/core";
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { HttpService, GlobalsService } from "@services";

@Component({
	selector: "document-viewer",
	template: `<div [innerHTML]="innerHtml"></div>`
})
export class DocumentViewerComponent implements OnChanges {
	@Input() documentId: number;
	@Input() height: string;

	innerHtml: SafeHtml;

	constructor(private httpService: HttpService, private _sanitizer: DomSanitizer) { }

	async ngOnChanges() {
		this.innerHtml = "";

		if (this.documentId) {
			if (!this.height)
				this.height = (document.body.clientHeight - 200) + "px";
			
			this.innerHtml = this._sanitizer.bypassSecurityTrustHtml(
				"<object style='height: " + this.height + "; min-height: " + this.height + ";width: 100%;' data='" + GlobalsService.apiUrl + "/documentView/getPDF?companyId=" + GlobalsService.company.companyId + "&documentId=" + this.documentId + "' type='application/pdf' class='embed-responsive-item'>" +
				"Object failed" +
				"</object>");
		}
	}
}