export interface IOpenHoursDayModel {
    dayOfWeek: number;
    dayOfWeekString: string;
    officeOpenHour: string;
    officeCloseHour: string;
    serviceOpenHour: string;
    serviceCloseHour: string;
    IsOfficeClosed: boolean;
    IsServiceClosed: boolean;
}

export class OpenHoursDayModel implements IOpenHoursDayModel {
    dayOfWeek: number;
    dayOfWeekString: string;
    officeOpenHour: string;
    officeCloseHour: string;
    serviceOpenHour: string;
    serviceCloseHour: string;
    IsOfficeClosed: boolean;
    IsServiceClosed: boolean;
}