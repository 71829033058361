import { IUserModel } from "../users/user.model";

export interface IPaymentRefundModel {
    paymentId: number;
    userId: number;
    userFullName: string;
    date: Date;
    refundAmound: number;


}

export class PaymentRefundModel implements IPaymentRefundModel {
    paymentId: number;
    userId: number;
    userFullName: string;
    date: Date;
    refundAmound: number;

}