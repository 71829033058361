export interface IJobListRequestModel {
    searchString: string;
    searchBillingAddress: boolean;
    showClosed: boolean;
    showOnlyJobsWithApptsInBilling: boolean;
    billingCustomerId: number;
    pageSize: number;
    page: number;
}

export class JobListRequestModel implements IJobListRequestModel {
    constructor() {
        this.searchString = "";
        this.searchBillingAddress = false;
        this.showClosed = false;
        this.showOnlyJobsWithApptsInBilling = false;
        this.billingCustomerId = -1;
        this.page = 1;
        this.pageSize = 50;
    }
    searchString: string;
    searchBillingAddress: boolean;
    showClosed: boolean;
    showOnlyJobsWithApptsInBilling: boolean;
    billingCustomerId: number;
    pageSize: number;
    page: number;

}