import { Component, Input, Output, EventEmitter, OnChanges, OnDestroy, SimpleChanges, AfterContentInit, ContentChildren, forwardRef, QueryList } from "@angular/core";
import { SlickSleepService } from "../utils/slick-sleep.service";
import { SlickAccordionItemComponent } from "./slick-accordion-item.component";
import { Subscription } from "rxjs";

@Component({
	selector: "slick-accordion",
	templateUrl: "slick-accordion.component.html"
})
export class SlickAccordionComponent implements OnChanges, AfterContentInit, OnDestroy {
	@Input() accordionIndex: number;
	@Output() accordionIndexChange: EventEmitter<number> = new EventEmitter<number>();
	@Input() keepExpanded: boolean = false;
	@Output() onChange: EventEmitter<number> = new EventEmitter<number>();

	@ContentChildren(forwardRef(() => SlickAccordionItemComponent)) slickAccordionItems: QueryList<SlickAccordionItemComponent>;

	private slickAccordionItems$: Subscription;

	constructor() { }

	async ngAfterContentInit() {
		this.slickAccordionItems$ = this.slickAccordionItems.changes.subscribe(async (slickAccordionItems: QueryList<SlickAccordionItemComponent>) => {
			// Go through each of the accordion items and set the callback to collapse
			slickAccordionItems.forEach((x, idx) => {
				x.index = idx;
				x.onChangeCallback = (index) => {
					if (this.keepExpanded === false && x.noExpand === false)
						this.collapseAll();

					if (this.accordionIndexChange)
						this.accordionIndexChange.emit(index)

					if (this.onChange) 
						this.onChange.emit(index);
				}
			});

			await SlickSleepService.sleep();
			if (this.accordionIndex !== undefined && this.accordionIndex !== null) 
				this.expand(this.accordionIndex);
		});

		await SlickSleepService.sleep();
		this.slickAccordionItems.notifyOnChanges();
	}

	public ngOnDestroy(): void {
		if (this.slickAccordionItems$)
			this.slickAccordionItems$.unsubscribe();
	}

	async ngOnChanges(changes: SimpleChanges) {
		this.keepExpanded = (this.keepExpanded.toString().toLowerCase() === 'true') ? true : false;

		if (this.keepExpanded === false && changes.accordionIndex) 
			this.expand(this.accordionIndex);		
	}

	expand(index: number) {
		if (!this.slickAccordionItems || (this.slickAccordionItems.length - 1) < index)
			return;

		this.slickAccordionItems.toArray()[index].expand();
	}

	collapse(index: number) {
		if (!this.slickAccordionItems || (this.slickAccordionItems.length - 1) < index)
			return;

		this.slickAccordionItems.toArray()[index].collapse();
	}

	getKey(index: number): string {
		return this.slickAccordionItems.toArray()[index].getKey();
	}

	getIndexByKey(key: string): number {
		if (!this.slickAccordionItems)
			return -1;

		return this.slickAccordionItems.toArray().findIndex(x => x.key === key);
	}

	collapseAll() {
		this.slickAccordionItems.forEach(x => x.collapse(true));
	}
}