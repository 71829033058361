import { JobsAuthService } from '@services/utils/auth-services/jobs-auth.service';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { RecurringAppointmentsService } from '@services';
import {
    IRecurringAppointmentModel,
} from '@models';
import Swal from 'sweetalert2'

@Component({
    selector: 'recurring-appointment-layout',
    templateUrl: './recurring-appointment-layout.component.html',
    styleUrls: ['./recurring-appointment-layout.component.scss'],
    providers: [RecurringAppointmentsService, JobsAuthService]
})
export class RecurringAppointmentLayoutComponent {
    @Input() selectedRecurringAppointment: IRecurringAppointmentModel;
    @Output() selectedRecurringAppointmentChange = new EventEmitter<
        IRecurringAppointmentModel
    >();
    spinnerStatus: string = 'reset';

    constructor(
        private readonly recurringAppointmentsService: RecurringAppointmentsService,
        public jobsAuthService: JobsAuthService
    ) {}

    async saveRecurringAppointment() {
        if (!this.selectedRecurringAppointment.scheduledTime) {
            await Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: 'Please provide valid Start Time value.',
                confirmButtonColor: '#007bff',
                heightAuto: false
            });
            return;
        }
        this.spinnerStatus = 'spin';
        await this.recurringAppointmentsService.updateRecurringAppointment(
            this.selectedRecurringAppointment
        );
        this.spinnerStatus = 'reset';
    }

    cancelRecurringAppointment() {
        this.selectedRecurringAppointment = null;
    }

    pauseRecurringAppointment() {}
}
