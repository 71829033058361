import {Component, ContentChild, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {CardItemTemplateDirective} from './card-item-template.directive';
import {IAuthService} from '@services/utils/auth.service';
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {GlobalsService, LookupService, UtilsService} from "@services";
import {DropdownModel, IDropdownModel, ResourceModel} from "@models";
import { SlickConfirmDialogComponent } from "@slick-components";

@Component({
    selector: 'default-layout',
    templateUrl: 'default-layout.component.html',
    styleUrls: ['default-layout.component.css']
})
export class DefaultLayoutComponent implements OnChanges {
    @Input('title') title: string;
    @Input('selectedItem') selectedItem: any;
    @Input('hide-add-button') hideAddButton: boolean = false;
    @Input('hide-header') hideHeader: boolean = false;
    @Input('hide-inactive-checkbox') hideInactiveCheckbox: boolean = false;
    @Input('show-invoice-statuses') showInvoiceStatuses: boolean = false;
    @Input('show-number-of-users') showNumberOfUsers: boolean = false;
    @Input('show-synced-checkbox') showSyncedCheckbox: boolean = false;
    @Input('show-unapplied-checkbox') showUnappliedCheckbox: boolean = false;
    @Input('show-locations') showLocations: boolean = false;
    @Input('items') items: any[];
    @Input('id-field') idField: string;
    @Input('color-field') colorField: string;
    @Input('search-field') searchField: string;
    @Input('auth-service') authService: IAuthService;
    @Input('sortable') sortable: boolean = false;
    @Output('onItemSelected')
    onItemSelected: EventEmitter<any> = new EventEmitter();
    @Output('onNew') onNew: EventEmitter<any> = new EventEmitter();
    @Output('onSearch') onSearch: EventEmitter<any> = new EventEmitter();
    @Output('onLocationChanged') onLocationChanged: EventEmitter<IDropdownModel> = new EventEmitter();
    @Output('onItemDragged') onItemDragged: EventEmitter<CdkDragDrop<string[]>> = new EventEmitter();

    numberOfUsers: number = GlobalsService.company.numberOfUsers;
    quickbooksEnabled: boolean = GlobalsService.company.quickbooksWebConnectorEnabled;
    totalActiveUsers: number

    @ViewChild("userMessageError", { static: true }) userMessageError: SlickConfirmDialogComponent;
    @ContentChild(CardItemTemplateDirective, { static: true })
    cardItemTemplateRef: CardItemTemplateDirective;
    maxHeight: string;

    visibleItems: any[];
	searchText: string = '';
    showInactive: boolean = false;
    invoiceStatuses: any = [
        {id: '0', text: 'All Invoices'},
        {id: '1', text: 'Open'},
        {id: '2', text: 'Closed'},
        {id: '3', text: 'Open/Closed'},
        {id: '4', text: 'Cancelled'},
        {id: '5', text: 'Pending'},
        {id: '6', text: 'Aquatech'}
    ];
    locations: IDropdownModel[] = [];
    selectedLocationId = 0;

    constructor(private lookupService: LookupService) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.items) {
            if (this.searchField) this.refreshVisibleItems();
            else this.visibleItems = this.items;
        }

        this.hideHeader = (this.hideHeader || 'false').toString().toLowerCase() === 'true';
        this.hideAddButton = (this.hideAddButton || 'false').toString().toLowerCase() === 'true';
        this.hideInactiveCheckbox = (this.hideInactiveCheckbox || 'false').toString().toLowerCase() === 'true';
        this.showSyncedCheckbox = (this.showSyncedCheckbox || 'false').toString().toLowerCase() === 'true';
        this.showInvoiceStatuses = (this.showInvoiceStatuses || 'false').toString().toLowerCase() === 'true';
        this.showNumberOfUsers = (this.showNumberOfUsers || 'false').toString().toLowerCase() === 'true';
        this.showUnappliedCheckbox = (this.showUnappliedCheckbox || 'false').toString().toLowerCase() === 'true';
        this.showLocations = (this.showLocations || 'false').toString().toLowerCase() === 'true';
        this.maxHeight = (this.hideHeader) ? "calc(100vh - 100px)" : "calc(100vh - 150px)";
        this.numberOfActiveUsers()

        if (this.showLocations) {
            this.locations = this.lookupService.getLocationsForDropdown();
            this.locations.unshift(new DropdownModel(0, 'Show All'));
        }
    }

    onShowInactiveChanged(showInactive) {
        this.showInactive = !this.showInactive;
        this.refreshVisibleItems();
    }

    onLocationSelected(location: IDropdownModel) {
        this.selectedLocationId = location.id;
        this.onLocationChanged.emit(location);
    }

    getItemColor(item) {
        if (this.colorField) return item[this.colorField];
    }

    async filterList(searchText: string) {
        this.searchText = searchText;
		this.refreshVisibleItems();
	}

    async refreshVisibleItems() {
        if (!this.items) return;

        if (!this.searchText)
            this.visibleItems = UtilsService.clone(this.items);
        else {
            this.visibleItems = [];
            let itemText = [];
            this.items.forEach(item => {
                itemText = item[this.searchField].toLowerCase().split(' ');
                itemText.forEach(word => {
                    if (word.indexOf(this.searchText) === 0) this.visibleItems.push(item);
                });
            });
        }

        if (this.hideInactiveCheckbox === false && this.showInactive === false) {
            this.visibleItems = this.visibleItems.filter(x => (x.active === null || x.active === undefined ? true : x.active) === true);
        }
    }

    onAdd() {
        this.onNew.emit();
    }

    isSelectedItem(item) {
        if (!item || !this.selectedItem) return false;

        return item[this.idField] === this.selectedItem[this.idField];
    }

    onClicked(item) {
        this.selectedItem = item;
        this.onItemSelected.emit(item);
    }

    onDropped(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.visibleItems, event.previousIndex, event.currentIndex);
        this.onItemDragged.emit(event);
    }

    numberOfActiveUsers() {
        if (this.quickbooksEnabled === true)
            this.totalActiveUsers = this.items?.filter(x => x.active === true).length - 1
        else {
            this.totalActiveUsers = this.items?.filter(x => x.active === true).length
        }
    }

    async userMessage() {
        await this.userMessageError.confirm();
    }
}
