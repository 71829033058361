import { ILathamOrderPickListLineItemModel } from "./latham-order-pick-list-line-item.model";

export interface ILathamOrderPickListModel {
	lathamOrderId: number;
	pickListLineItems: ILathamOrderPickListLineItemModel[];
}

export class LathamOrderPickListModel implements ILathamOrderPickListModel {
	lathamOrderId: number;
	pickListLineItems: ILathamOrderPickListLineItemModel[];
}