import { Component, Input, Output, EventEmitter, ElementRef, AfterViewInit, OnDestroy, OnChanges, SimpleChanges, ViewChild, forwardRef } from "@angular/core";
import { SlickMultiSelectService } from "./slick-multi-select.service";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { SlickSleepService } from "../utils/slick-sleep.service";
import { SlickUtilsService } from "../utils/slick-utils.service";
import { SlickInitService } from "../utils/slick-init.service";

export enum SlickMultiSelectSearchTypes { startsWith, eachWord, any }

@Component({
	selector: 'slick-multi-select',
	templateUrl: 'slick-multi-select.component.html',
	providers: [SlickMultiSelectService,
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SlickMultiSelectComponent),
			multi: true,
		}]
})
export class SlickMultiSelectComponent implements AfterViewInit, OnChanges, OnDestroy, ControlValueAccessor {
	@Input() placeholder: string = '';
	@Input() items: any[];
	@Input() idFieldName: string = "id";
	@Input() textFieldName: string = "text";
	@Input() disabled: boolean = false;
	@Input() allowEmpty: string = 'true';
	@Input() singleSelect: boolean = false;
	@Input() width: string = '100%';
	@Input() listWidth: string = '100%';
	@Input() height: string = '300px';
	@Input() showLoadingMessage: boolean = false;
	@Input() showDebug: boolean;
	@Input() tabindex: number;
	@Input() searchType: SlickMultiSelectSearchTypes = SlickMultiSelectSearchTypes.eachWord;

	@Output() onExpand: EventEmitter<void> = new EventEmitter<void>();
	@Output() onCollapse: EventEmitter<void> = new EventEmitter<void>();
	@Output() onSelect: EventEmitter<string> = new EventEmitter<string>();

	@ViewChild("containerDiv") containerDiv: ElementRef;
	@ViewChild("multiSelectList") multiSelectList: ElementRef;
	@ViewChild("placeholderInputRef") placeholderInputRef: ElementRef;
	@ViewChild("filterTextInputRef") filterTextInputRef: ElementRef;

	// This is necessary so that the scroll and resize events can add and remove the event properly,
	// as well as getting the correct ES6 function
	private fnDocumentClick = (e) => this.documentClick(e);

	uuid: string;
	selectedIds: string[];
	selectedText: string;
	expanded: boolean = false;
	filterText: string = '';
	selectedIndex: number;
	selectedItems: any[];
	visibleItems: any[];
	left: string;
	top: string;
	listGroupWidth: string;
	isAndroid: boolean = ((SlickInitService.getPlatform() || '').toLowerCase() === 'android');

	constructor(private slickMultiSelectService: SlickMultiSelectService) {
		this.uuid = SlickUtilsService.newGuid();
	}

	async ngAfterViewInit() {
		// Remove this from the inline version and put it on the body so that it doesn't hide behind the bottom
		// of a div when it expands beyond the bottom.
		this.multiSelectList.nativeElement.remove(this.multiSelectList);
		document.body.appendChild(this.multiSelectList.nativeElement);
	}

	propagateChange = (_: any) => { };

	// this is the initial value set to the component
	public writeValue(obj: any) {
		if (obj)
			this.selectedIds = obj;
		else
			this.selectedIds = null;

		this.visibleItems = this.slickMultiSelectService.getVisibleItems(this.items, this.selectedIds, this.idFieldName, this.textFieldName);
		this.selectedItems = this.slickMultiSelectService.getSelectedItems(this.items, this.selectedIds, this.idFieldName, this.textFieldName);
	}

	// registers 'fn' that will be fired when changes are made
	// this is how we emit the changes back to the form
	public registerOnChange(fn: any) {
		this.propagateChange = fn;
	}

	// not used, used for touch input
	public registerOnTouched() { }

	ngOnChanges(changes: SimpleChanges) {
		if (changes.singleSelect) {
			this.singleSelect = (this.singleSelect.toString().toLowerCase() === 'true') ? true : false;
		}

		if (changes.searchType) {
			if (changes.searchType.currentValue.toString().toLowerCase() === 'startswith')
				this.searchType = SlickMultiSelectSearchTypes.startsWith;
			else if (changes.searchType.currentValue.toString().toLowerCase() === 'any')
				this.searchType = SlickMultiSelectSearchTypes.any;
			else
				this.searchType = SlickMultiSelectSearchTypes.eachWord;
		}

		if (changes.items) {
			this.visibleItems = this.slickMultiSelectService.getVisibleItems(this.items, this.selectedIds, this.idFieldName, this.textFieldName);
			this.selectedItems = this.slickMultiSelectService.getSelectedItems(this.items, this.selectedIds, this.idFieldName, this.textFieldName);
			if (this.filterTextInputRef && document.activeElement == this.filterTextInputRef.nativeElement)
				this.expand();
		}
	}

	ngOnDestroy() {
		this.collapse();
		document.removeEventListener("click", this.fnDocumentClick, true);

		// it's okay if this fails.  It was already removed
		try {
			document.body.removeChild(this.multiSelectList.nativeElement);
		}
		catch { }

	}

	async expand() {
		if (this.disabled)
			return;

		this.reposition();

		await SlickSleepService.sleep();
		this.multiSelectList.nativeElement.style.display = "inline-block";

		let selectedItems = this.multiSelectList.nativeElement.getElementsByClassName("selected");
		if (selectedItems && selectedItems.length > 0)
			(<HTMLElement>selectedItems[0]).scrollIntoView({ block: 'nearest' });

		this.expanded = true;

		await SlickSleepService.sleep();

		(<HTMLInputElement>this.filterTextInputRef.nativeElement).focus();

		// This is necessary, otherwise `this` in the reposition function will be window
		// and not the component.
		// Since angular has no way to use the third parameter, I'm doing this in raw js
		document.addEventListener("click", this.fnDocumentClick, true);

		if (this.onExpand)
			this.onExpand.emit();
	}

	collapse() {
		this.expanded = false;
		this.multiSelectList.nativeElement.style.display = "none";
		// Since angular has no way to use the third parameter, I'm doing this in raw js
		document.removeEventListener("click", this.fnDocumentClick, true);

		if (this.onCollapse)
			this.onCollapse.emit();
	}

	async reposition() {
		await SlickSleepService.sleep();
		let rect = this.containerDiv.nativeElement.getBoundingClientRect();
		let containerLeft = rect.left;
		let containerTop = rect.top;
		let containerHeight = this.containerDiv.nativeElement.offsetHeight;
		let containerWidth = rect.width;

		this.width = containerWidth;
		if (this.listWidth === '100%')
			this.listGroupWidth = this.width;
		else if (this.listWidth === 'auto')
			this.listGroupWidth = "auto";
		else
			this.listGroupWidth = this.listWidth;
		this.top = (containerTop + containerHeight);
		this.left = containerLeft;
	}

	private documentClick(e: MouseEvent) {
		if (!this.expanded || !e.target)
			return;

		// If they clicked on the keyboard icon, don't do anything
		if (SlickUtilsService.checkParentClassExists(<HTMLElement>e.target, "slick-multi-select_mobile-container") &&
			SlickUtilsService.checkParentIdExists(<HTMLElement>e.target, "slick-multi-select_" + this.uuid))
			return;

		const clickedInside = SlickUtilsService.checkParentClassExists(<HTMLElement>e.target, "slick-multi-select_list-item");
		if (!clickedInside) {
			this.selectedText = '';
			(<HTMLInputElement>this.filterTextInputRef.nativeElement).value = '';
			this.collapse();
		}
	}

	private selectItem(id: string) {
		let item = this.slickMultiSelectService.getSelectedItem(this.visibleItems, id);

		if (item) {

			if (this.onSelect)
				this.onSelect.emit(item);

			if (!this.selectedIds)
				this.selectedIds = [];

			this.selectedIds.push(id);
			this.propagateChange(this.selectedIds);

			this.visibleItems = this.slickMultiSelectService.getVisibleItems(this.items, this.selectedIds, this.idFieldName, this.textFieldName);
			this.selectedItems = this.slickMultiSelectService.getSelectedItems(this.items, this.selectedIds, this.idFieldName, this.textFieldName);

			this.reposition();

			if (this.singleSelect === true)
				this.collapse();
		}

		this.selectedText = '';
		this.filterText = '';
		this.visibleItems = this.slickMultiSelectService.getVisibleItems(this.items, this.selectedIds, this.idFieldName, this.textFieldName);

		(<HTMLInputElement>this.filterTextInputRef.nativeElement).value = '';
	}

	setFocus() {
		this.expand();
	}

	async onFocus() {
		this.visibleItems = this.slickMultiSelectService.getVisibleItems(this.items, this.selectedIds, this.idFieldName, this.textFieldName);
		this.selectedItems = this.slickMultiSelectService.getSelectedItems(this.items, this.selectedIds, this.idFieldName, this.textFieldName);

		this.filterText = '';
		this.expand();
	}

	async expandOnKeyUp(e: KeyboardEvent) {

		if (e.key.length == 1 || (e.key.length > 1 && /[^a-zA-Z0-9]/.test(e.key))) {
			this.expand();			
			(<HTMLInputElement>this.placeholderInputRef.nativeElement).value = '';

			this.onKeyDown(e);
		}
	}

	async onKeyDown(e: KeyboardEvent) {
		if (e.key !== 'Tab' &&
			e.key !== 'Enter' &&
			e.key !== 'Escape' &&
			e.key !== 'Backspace' &&
			e.key !== 'ArrowUp' &&
			e.key !== 'ArrowDown'
		) {
			// If F1 is pressed, key length will be 2
			if (e.key.length > 1 || e.ctrlKey || e.altKey) {
				return false;
			}
		}

		//e.stopPropagation();
		//e.preventDefault();

		// If this is android, it won't pass us any key data so just read the entire input element
		if (this.isAndroid === true) {
			await SlickSleepService.sleep();

			this.filterText = (<HTMLInputElement>this.filterTextInputRef.nativeElement).value;
			this.filterSearch();
			return;
		}

		// Tab/enter
		if (e.key === 'Tab') {
			if (this.filterText && this.visibleItems.length > 0) {
				this.selectItem(this.visibleItems[0].id);
			}

			this.collapse();

			this.filterText = '';
			this.selectedText = '';
			(<HTMLInputElement>this.filterTextInputRef.nativeElement).value = '';
			this.selectedIndex = null;

			const currentNode = <HTMLElement>(this.placeholderInputRef.nativeElement);
			//find all tab-able elements
			const allElements = document.querySelectorAll('input, button, a, area, object, select, textarea, [contenteditable]');

			//Find the current tab index.
			const currentIndex = Array.from(allElements).findIndex(el => currentNode.isEqualNode(el))

			//focus the following element
			const targetIndex = (currentIndex + 1) % allElements.length;
			(<HTMLElement>allElements[targetIndex]).focus();

			e.preventDefault();

			return false;
		}

		if (e.key === 'Enter') {
			if (this.filterText && this.selectedIndex !== null && this.selectedIndex !== undefined) {
				this.selectItem(this.visibleItems[this.selectedIndex].id);
				this.filterText = '';
				this.selectedIndex = null;
				return false;
			}

			this.collapse();

			this.filterText = '';
			this.selectedText = '';
			(<HTMLInputElement>this.filterTextInputRef.nativeElement).value = '';
			this.selectedIndex = null;

			return true;
		}

		// Esc
		if (e.key === 'Escape') {
			this.filterText = '';
			this.selectedText = '';
			this.visibleItems = this.slickMultiSelectService.getVisibleItems(this.items, this.selectedIds, this.idFieldName, this.textFieldName);

			(<HTMLInputElement>this.filterTextInputRef.nativeElement).value = '';
			this.collapse();

			return false;
		}

		// Backspace
		if (e.key === 'Backspace' && this.filterText) {
			this.filterText = this.filterText.substring(0, this.filterText.length - 1);
			if (this.filterText.length === 0) {
				this.selectedText = '';
				(<HTMLInputElement>this.filterTextInputRef.nativeElement).value = '';
				this.selectedIndex = null;
				this.visibleItems = this.slickMultiSelectService.getVisibleItems(this.items, this.selectedIds, this.idFieldName, this.textFieldName);
				this.selectedItems = this.slickMultiSelectService.getSelectedItems(this.items, this.selectedIds, this.idFieldName, this.textFieldName);
			}
			else
				this.filterSearch();
			return false;
		}

		// Arrowup
		if (e.key === 'ArrowUp') {
			if (this.selectedIndex === null || this.selectedIndex === undefined)
				this.selectedIndex = 0;

			if (this.selectedIndex > 0)
				this.selectedIndex--;

			return false;
		}

		// Arrowdown
		if (e.key === 'ArrowDown') {
			if (this.selectedIndex === null || this.selectedIndex === undefined)
				this.selectedIndex = -1;

			if (!this.expanded || !this.visibleItems)
				this.expand();

			if (this.selectedIndex < this.visibleItems.length - 1)
				this.selectedIndex++;

			return false;
		}

		// If F1 is pressed, key length will be 2
		if (e.key.length > 1 || e.ctrlKey || e.altKey)
			return false;

		if (e.key >= ' ' && e.key <= '~') {
			this.filterText += e.key;

			this.filterSearch();
		}

		return true;
	}

	private async filterSearch() {
		if (!this.expanded)
			this.expand();

		this.visibleItems = this.slickMultiSelectService.getVisibleItems(this.items, this.selectedIds, this.idFieldName, this.textFieldName);

		if (this.searchType === SlickMultiSelectSearchTypes.startsWith) {
			this.visibleItems = this.visibleItems.filter(x => (x.text ?? "").toLowerCase().indexOf(this.filterText.toLowerCase()) === 0);
			if (this.visibleItems.length > 0) {
				this.selectedIndex = 0;
				this.selectedText = this.visibleItems[0].text;
				if (this.isAndroid === false) {
					await SlickSleepService.sleep();
					(<HTMLInputElement>this.filterTextInputRef.nativeElement).value = this.selectedText;
					(<HTMLInputElement>this.filterTextInputRef.nativeElement).setSelectionRange(this.filterText.length, this.selectedText.length);
				}
			}
			else {
				this.selectedIndex = null;
				if (this.isAndroid === false) {
					this.selectedText = this.filterText;
					(<HTMLInputElement>this.filterTextInputRef.nativeElement).value = this.selectedText;
				}

			}
		}
		else if (this.searchType === SlickMultiSelectSearchTypes.eachWord) {

			// Get the words where the beginning matches the search first
			const startsWithItems = this.visibleItems.filter(x => (x.text ?? "").toLowerCase().indexOf(this.filterText.toLowerCase()) === 0);
			const eachWordItems = this.visibleItems.filter(x => (" " + (x.text ?? "").toLowerCase()).indexOf(" " + this.filterText.toLowerCase()) > 0);

			this.visibleItems = [...startsWithItems, ...eachWordItems];

			if (this.visibleItems.length > 0) {
				this.selectedIndex = 0;
				this.selectedText = this.visibleItems[0].text;
				if (this.isAndroid === false) {
					await SlickSleepService.sleep();
					(<HTMLInputElement>this.filterTextInputRef.nativeElement).value = this.selectedText;
					(<HTMLInputElement>this.filterTextInputRef.nativeElement).setSelectionRange(this.filterText.length, this.selectedText.length);
				}
			}
			else {
				this.selectedText = this.filterText;
				this.selectedIndex = null;
			}

		}

		this.reposition();
	}

	onMultiSelectClicked() {
		if (this.expanded === false)
			this.expand();
		else
			this.collapse();
	}

	onItemClick(item: any) {
		this.selectItem(item.id);

		(<HTMLInputElement>this.filterTextInputRef.nativeElement).focus();
	}

	onRemoveItem(item: any) {
		this.selectedIds = this.selectedIds.filter(id => id !== item.id);
		this.visibleItems = this.slickMultiSelectService.getVisibleItems(this.items, this.selectedIds, this.idFieldName, this.textFieldName);
		this.selectedItems = this.slickMultiSelectService.getSelectedItems(this.items, this.selectedIds, this.idFieldName, this.textFieldName);

		this.propagateChange(this.selectedIds);
	}
}