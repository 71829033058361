export interface IJobStatusModel {
    jobStatusId: number;
    description: string;
    active: boolean;
}

export class JobStatusModel implements IJobStatusModel {
    jobStatusId: number;
    description: string;
    active: boolean;
}