// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.state-btn {
  width: 25px;
  height: 25px;
  padding: 0px;
  color: #343a40;
  border-color: #e9ecef;
  background-color: #e9ecef;
  font-size: 10px;
}`, "",{"version":3,"sources":["webpack://./app-desktop/src/app/appointments/components/appointment-edit/appointment-edit.component.scss"],"names":[],"mappings":"AACA;EACI,WAAA;EACA,YAAA;EACA,YAAA;EACA,cAAA;EACA,qBAAA;EACA,yBAAA;EACA,eAAA;AAAJ","sourcesContent":["\r\n.state-btn {\r\n    width: 25px;\r\n    height: 25px;\r\n    padding: 0px;\r\n    color: #343a40;\r\n    border-color: #e9ecef;\r\n    background-color: #e9ecef;\r\n    font-size: 10px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
