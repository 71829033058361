// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resource {
  font-size: 14px;
  line-height: 14px;
  border-radius: 8px;
  margin-left: 3px;
  padding: 7px 10px !important;
  cursor: pointer;
  white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./app-desktop/src/app/auto-scheduler/auto-scheduler.component.scss"],"names":[],"mappings":"AAAA;EACC,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,gBAAA;EACA,4BAAA;EACA,eAAA;EACA,mBAAA;AACD","sourcesContent":[".resource {\r\n\tfont-size: 14px;\r\n\tline-height: 14px;\r\n\tborder-radius: 8px;\r\n\tmargin-left: 3px;\r\n\tpadding: 7px 10px !important;\r\n\tcursor: pointer;\r\n\twhite-space: nowrap;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
