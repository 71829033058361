export interface IScheduledAppointmentModel {
    appointmentId: number;
    companyId: number;
    sequence: number;
    recurringAppointmentId: number;
    customerId?: number;
    oneTimeAddressId?: number;
    appointmentBlockStart: Date;
    appointmentBlockEnd: Date;
    scheduledDateTime: Date;
    scheduledMinutes: number;
    travelMinutes: number;
    appointmentStatusId: number;
    resourceId: number;
    locationId: number;
    appointmentTypeDescription: string;
    appointmentStatusColor: string;
    borderColor: string;
    jobDisplayName: string;
    jobCity: string;
    billToDisplayName: string;
    creditHold: boolean;
    locked: boolean;
    goBack: boolean;
    unscheduled?: boolean;
    unscheduledDisplaySeq?: number;
    description: string;
    inTransitStartTime?: Date;
    atJobsiteStartTime?: Date;
    finishedTime?: Date;
    acceptedTerms: boolean;
    photoCount: number;
    serviceAreaColor: string;
    removeServiceAreaColor: boolean;
    showBorderOnMiniSchedule: boolean;
    showOnSchedule: boolean;
    latitude?: number;
    longitude?: number;
    jobFullAddress: string;
    optimalRouteSeq: number;
    isConfirmed: boolean;
}

export class ScheduledAppointmentModel implements IScheduledAppointmentModel {
    appointmentId: number;
    companyId: number;
    sequence: number;
    recurringAppointmentId: number;
    customerId?: number;
    oneTimeAddressId?: number;
    appointmentBlockStart: Date;
    appointmentBlockEnd: Date;
    scheduledDateTime: Date;
    scheduledMinutes: number;
    travelMinutes: number;
    appointmentStatusId: number;
    resourceId: number;
    locationId: number;
    appointmentTypeDescription: string;
    appointmentStatusColor: string;
    borderColor: string;
    jobDisplayName: string;
    jobCity: string;
    billToDisplayName: string;
    creditHold: boolean;
    locked: boolean;
    goBack: boolean;
    unscheduled?: boolean;
    unscheduledDisplaySeq?: number;
    description: string;
    inTransitStartTime?: Date;
    atJobsiteStartTime?: Date;
    finishedTime?: Date;
    acceptedTerms: boolean;
    photoCount: number;
    serviceAreaColor: string;
    removeServiceAreaColor: boolean;
    showBorderOnMiniSchedule: boolean;
    showOnSchedule: boolean;
    latitude?: number;
    longitude?: number;
    jobFullAddress: string;
    optimalRouteSeq: number;
    isConfirmed: boolean;
}
