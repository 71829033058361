// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inline-40 {
    display: inline-block;
    line-height: 40px;
    margin-right: 5px;
}

.desc {
    font-size: 12px;
    line-height: 20px;
    width: 360px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.condensed .form-control {
	padding: 0;
	line-height: 1rem;
	height: 26px;
	padding-right: 5px;
	padding-left: 5px;
}`, "",{"version":3,"sources":["webpack://./app-desktop/src/components/item-search/item-search.component.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,YAAY;IACZ,mBAAmB;IACnB,gBAAgB;IAChB,uBAAuB;AAC3B;;AAEA;CACC,UAAU;CACV,iBAAiB;CACjB,YAAY;CACZ,kBAAkB;CAClB,iBAAiB;AAClB","sourcesContent":[".inline-40 {\r\n    display: inline-block;\r\n    line-height: 40px;\r\n    margin-right: 5px;\r\n}\r\n\r\n.desc {\r\n    font-size: 12px;\r\n    line-height: 20px;\r\n    width: 360px;\r\n    white-space: nowrap;\r\n    overflow: hidden;\r\n    text-overflow: ellipsis;\r\n}\r\n\r\n.condensed .form-control {\r\n\tpadding: 0;\r\n\tline-height: 1rem;\r\n\theight: 26px;\r\n\tpadding-right: 5px;\r\n\tpadding-left: 5px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
