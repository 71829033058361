export interface IJobCostingDetailModel {
    productRevenue: number;
    laborRevenue: number;
    totalRevenue: number;
    directMaterial: number;
    directLabor: number;
    totalCOGS: number;
    grossMargin: number;
    indirectLaborPerJob: number;
    overheadCostsPerJob: number;
    totalSGA: number;
    netIncome: number;
    createdDate: Date;
    invoiceId: number;
    jobId: number;
    isClosed: boolean;
}

export class JobCostingDetailModel implements IJobCostingDetailModel {
    productRevenue: number;
    laborRevenue: number;
    totalRevenue: number;
    directMaterial: number;
    directLabor: number;
    totalCOGS: number;
    grossMargin: number;
    indirectLaborPerJob: number;
    overheadCostsPerJob: number;
    totalSGA: number;
    netIncome: number;
    createdDate: Date;
    invoiceId: number;
    jobId: number;
    isClosed: boolean;
}
