export interface IMechanismDashboardModel{
    lathamOrderId: number;
    orderId: string;
    distributorCompanyName: string;
    distributorName: string;
    builderCompanyName: string;
    builderName: string;
    homeOwnerCompanyName: string;
    homeOwnerName: string;
    submittedDate: Date;
    lathamShipDate: Date;
    purchaseOrderNumber: string;
    mechanismReady: boolean;
    mechanismDelivered: boolean;
}

export class MechanismDashboardModel implements IMechanismDashboardModel {
    lathamOrderId: number;
    orderId: string;
    distributorCompanyName: string;
    distributorName: string;
    builderCompanyName: string;
    builderName: string;
    homeOwnerCompanyName: string;
    homeOwnerName: string;
    submittedDate: Date;
    lathamShipDate: Date;
    purchaseOrderNumber: string;
    mechanismReady: boolean;
    mechanismDelivered: boolean;
}