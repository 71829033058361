import { NgModule } from "@angular/core";
import { SlickTypeAheadDirective } from "./slick-type-ahead.directive";

@NgModule({
	imports: [],
	declarations: [SlickTypeAheadDirective],
	exports: [SlickTypeAheadDirective]
})
export class SlickTypeAheadModule { }
export { SlickTypeAheadDirective }
