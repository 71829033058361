// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customer-list-item {
  line-height: 1rem;
  font-size: 12px;
  list-style: none;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
}

.customer-display .customer-list-item {
  padding: 0px;
  margin: 0px;
}

.customer-list-item li.bigger {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 3px;
}

.customer-display .customer-info {
  padding: 5px;
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.customer-display .customer-info ul li {
  line-height: 16px;
  font-size: 14px;
}

.customer-display .customer-info ul li.bigger {
  font-size: 18px;
}

.alert-section {
  margin-top: 2px;
}

.alert-section > div {
  border: none;
  color: white;
  font-size: 12px;
  padding: 3px 5px;
  margin-left: 3px;
}

.alert-section .cc-digits {
  letter-spacing: 1px;
}

.alert-section button {
  color: white;
  position: relative;
  bottom: 7px;
  padding: 0;
  margin: 0;
  line-height: 30px;
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./app-desktop/src/components/customer-display/customer-display.component.scss"],"names":[],"mappings":"AAAA;EACC,iBAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,mBAAA;EACA,iBAAA;AACD;;AAEA;EACC,YAAA;EACA,WAAA;AACD;;AAEA;EACC,iBAAA;EACA,eAAA;EACA,kBAAA;AACD;;AAEA;EACC,YAAA;EACA,eAAA;EACA,cAAA;EACA,iBAAA;AACD;;AAEA;EACC,iBAAA;EACA,eAAA;AACD;;AAEA;EACC,eAAA;AACD;;AAEA;EACC,eAAA;AACD;;AAEA;EACC,YAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AACD;;AAEA;EACC,mBAAA;AACD;;AAEA;EACC,YAAA;EACA,kBAAA;EACA,WAAA;EACA,UAAA;EACA,SAAA;EACA,iBAAA;EACA,eAAA;AACD","sourcesContent":[".customer-list-item {\r\n\tline-height: 1rem;\r\n\tfont-size: 12px;\r\n\tlist-style: none;\r\n\tpadding-top: 5px;\r\n\tpadding-bottom: 5px;\r\n\tpadding-left: 5px;\r\n}\r\n\r\n.customer-display .customer-list-item {\r\n\tpadding: 0px;\r\n\tmargin: 0px;\r\n}\r\n\r\n.customer-list-item li.bigger {\r\n\tfont-weight: bold;\r\n\tfont-size: 14px;\r\n\tmargin-bottom: 3px;\r\n}\r\n\r\n.customer-display .customer-info {\r\n\tpadding: 5px;\r\n\tpadding-left: 0;\r\n\tpadding-top: 0;\r\n\tpadding-bottom: 0;\r\n}\r\n\r\n.customer-display .customer-info ul li {\r\n\tline-height: 16px;\r\n\tfont-size: 14px;\r\n}\r\n\r\n.customer-display .customer-info ul li.bigger {\r\n\tfont-size: 18px;\r\n}\r\n\r\n.alert-section {\r\n\tmargin-top: 2px;\r\n}\r\n\r\n.alert-section > div {\r\n\tborder: none;\r\n\tcolor: white;\r\n\tfont-size: 12px;\r\n\tpadding: 3px 5px;\r\n\tmargin-left: 3px;\r\n}\r\n\r\n.alert-section .cc-digits {\r\n\tletter-spacing: 1px;\r\n}\r\n\r\n.alert-section button {\r\n\tcolor: white;\r\n\tposition: relative;\r\n\tbottom: 7px;\r\n\tpadding: 0;\r\n\tmargin: 0;\r\n\tline-height: 30px;\r\n\tfont-size: 12px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
