export interface IQuickbooksSettingsModel {
    isAuthenticated: boolean;
    allowRedRoverToAddItems?: boolean;
    defaultIncomeAccountId: string;
    defaultCOGSAccountId: string;
    defaultAssetAccountId: string;
    defaultCategoryId: string;
    labor1QuickbooksId: string;
    labor2QuickbooksId: string;
    labor3QuickbooksId: string;
    labor4QuickbooksId: string;
    shippingQuickbooksId: string;
    invoiceNumberCustomFieldMapping: string;
    quickbooksFileLocation: string;
    undepositedFundsAccountId: string;
    customerLastCommaFirst: boolean;

    defaultNonInventoryItemAccountId: string;
    defaultServiceItemAccountId: string;
    defaultDiscountItemAccountId: string;
    defaultOtherChargeItemAccountId: string;
}

export class QuickbooksSettingsModel implements IQuickbooksSettingsModel {
	constructor() {
	}

    isAuthenticated: boolean;
    allowRedRoverToAddItems?: boolean;
    defaultIncomeAccountId: string;
    defaultCOGSAccountId: string;
    defaultAssetAccountId: string;
    defaultCategoryId: string;
    labor1QuickbooksId: string;
    labor2QuickbooksId: string;
    labor3QuickbooksId: string;
    labor4QuickbooksId: string;
    shippingQuickbooksId: string;
    invoiceNumberCustomFieldMapping: string;
    quickbooksFileLocation: string;
    undepositedFundsAccountId: string;
    customerLastCommaFirst: boolean;

    defaultNonInventoryItemAccountId: string;
    defaultServiceItemAccountId: string;
    defaultDiscountItemAccountId: string;
    defaultOtherChargeItemAccountId: string;
}