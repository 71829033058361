export interface IInventoryItemLocationModel {
	inventoryItemLocationId: number;
	inventoryLocationId: number;
	inventoryWarehouseId: number;
	itemId: number;
	locationName: string;
	quantity: number;
	allocated: number;
	isDefault: boolean;
	isShippingLocation: boolean;
	isReceivingLocation: boolean;
	isStockLocation: boolean;
	showOnShortList: boolean;

}

export class InventoryItemLocationModel implements IInventoryItemLocationModel {
	inventoryItemLocationId: number;
	inventoryLocationId: number;
	inventoryWarehouseId: number;
	itemId: number;
	locationName: string;
	quantity: number;
	allocated: number;
	isDefault: boolean;
	isShippingLocation: boolean;
	isReceivingLocation: boolean;
	isStockLocation: boolean;
	showOnShortList: boolean;
}