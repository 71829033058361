// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.striped > tbody > tr:nth-of-type(even) > * {
  --bs-table-color-type: var(--bs-table-striped-color) !important;
  --bs-table-bg-type: var(--bs-table-striped-bg) !important; /* Set your desired background color for even rows (previously odd) */
}

.table tbody tr td {
  font-size: 16px;
  padding-top: 4px;
  padding-bottom: 3px;
  padding-left: 4px;
  padding-right: 4px;
  line-height: 2rem;
  border-bottom: 1px solid var(--bs-border-color);
  border-top: none;
  vertical-align: middle;
}`, "",{"version":3,"sources":["webpack://./app-desktop/src/app/payments/payments-components/payment-edit/payment-edit.component.scss"],"names":[],"mappings":"AACA;EACC,+DAAA;EACG,yDAAA,EAAA,qEAAA;AAAJ;;AAIA;EACC,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,iBAAA;EACA,kBAAA;EACA,iBAAA;EACA,+CAAA;EACA,gBAAA;EACA,sBAAA;AADD","sourcesContent":["\r\n.striped>tbody>tr:nth-of-type(even)>* {\r\n\t--bs-table-color-type: var(--bs-table-striped-color) !important;\r\n    --bs-table-bg-type: var(--bs-table-striped-bg) !important; /* Set your desired background color for even rows (previously odd) */\r\n}\r\n\r\n\r\n.table tbody tr td {\r\n\tfont-size: 16px;\r\n\tpadding-top: 4px;\r\n\tpadding-bottom: 3px;\r\n\tpadding-left: 4px;\r\n\tpadding-right: 4px;\r\n\tline-height: 2rem;\r\n\tborder-bottom: 1px solid var(--bs-border-color);\r\n\tborder-top: none;\r\n\tvertical-align: middle;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
