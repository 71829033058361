export interface IAvailableTimeSlotModel {
    resourceId: number;
    startDateTime: Date;
    duration: number;
    inArea: boolean;
}

export class AvailableTimeSlotModel implements IAvailableTimeSlotModel {
    resourceId: number;
    startDateTime: Date;
    duration: number;
    inArea: boolean;
}