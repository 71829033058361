import { IScheduledAppointmentModel, ScheduledAppointmentModel } from "@models/appointments/scheduled-appointment.model";
import { IBlockedTimeSlotModel, BlockedTimeSlotModel } from "@models/appointments/blocked-time-slots.model";

export interface IConflictingAppointmentResultModel {
    hasConflict: boolean;
    appointment: IScheduledAppointmentModel;
    conflictingAppointments: IScheduledAppointmentModel[];
    conflictingBlockedTimeSlots: IBlockedTimeSlotModel[];
}

export class ConflictingAppointmentResultModel {
    hasConflict: boolean;
    appointment: IScheduledAppointmentModel;
    conflictingAppointments: IScheduledAppointmentModel[];
    conflictingBlockedTimeSlots: IBlockedTimeSlotModel[];
}