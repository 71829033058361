// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.minHeight {
	min-height: calc(100vh - 180px);
	max-height: calc(100vh - 180px);
	overflow-y: auto;
}

`, "",{"version":3,"sources":["webpack://./app-desktop/src/app/sales-orders/sales-orders-components/sales-order-edit/sales-order-edit.component.css"],"names":[],"mappings":"AAAA;CACC,+BAA+B;CAC/B,+BAA+B;CAC/B,gBAAgB;AACjB","sourcesContent":[".minHeight {\r\n\tmin-height: calc(100vh - 180px);\r\n\tmax-height: calc(100vh - 180px);\r\n\toverflow-y: auto;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
