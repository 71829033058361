export interface IMaintenanceInventoryModel {
	maintenanceInventoryId: number;
	customerId: number
	name: string;
	quantity: number
	active: boolean
}

export class MaintenanceInventoryModel implements IMaintenanceInventoryModel {
	maintenanceInventoryId: number;
	customerId: number
	name: string;
	quantity: number
	active: boolean
}