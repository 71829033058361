export interface ISkeditPayRecurringPaymentHistoryModel {
    skeditPayRecurringPaymentHistoryId: number;
    skeditPayRecurringPaymentId: number;
    paymentId: number;
    creditCardTransactionLogId: number;
    billingHistoryCreatedDate: Date;
    amount: number;
    cardNumber: string;
    refNumber: string;
    status: string;
    statusError: string;
    canRetryPayment: boolean;
    accountNumberLastFour: string;


}

export class SkeditPayRecurringPaymentHistoryModel implements ISkeditPayRecurringPaymentHistoryModel {
    skeditPayRecurringPaymentHistoryId: number;
    skeditPayRecurringPaymentId: number;
    paymentId: number;
    creditCardTransactionLogId: number;
    billingHistoryCreatedDate: Date;
    amount: number;
    cardNumber: string;
    refNumber: string;
    status: string;
    statusError: string;
    canRetryPayment: boolean;
    accountNumberLastFour: string;
}