export interface ISystemLogModel {
    systemLogId: number;
    userName: string;
    logDate: Date;
    logText: string;
    systemLogDetails: ISystemLogDetailModel[];
}

export interface ISystemLogDetailModel {
    systemLogDetailId: number;
    detailText: string;
}

export class SystemLogModel implements ISystemLogModel {
    systemLogId: number;
    userName: string;
    logDate: Date;
    logText: string;
    systemLogDetails: ISystemLogDetailModel [];

}

export class SystemLogDetailModel implements ISystemLogDetailModel {
    systemLogDetailId: number;
    detailText: string;
}

export enum SystemLogType {
    Customer,
    Job,
    Invoice,
    Payment,
    LathamOrder,
    Vendor,
    PurchaseOrder
}