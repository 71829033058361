// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.row .col-4 {
    text-align: center;
}

/* .credit-card-div span {
    padding-top: 10px;
} */

.credit-card-div img {
    padding-top: 30px;
}

/* .credit-card-div .small-font {
    font-size: 15px;
    line-height: 30px;
} */

.credit-card-div .pad-adjust {
    padding-top: 10px;
}

.card-last-four {
    font-size: 18px;
    display: block;
    letter-spacing: 2px;
    padding-bottom: 20px;
}

label{
    font-weight: 500;
    padding-top: 4px;
}`, "",{"version":3,"sources":["webpack://./app-desktop/src/components/credit-card-dialog/credit-card-edit-dialog/credit-card-edit-dialog.component.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;;GAEG;;AAEH;IACI,iBAAiB;AACrB;;AAEA;;;GAGG;;AAEH;IACI,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,cAAc;IACd,mBAAmB;IACnB,oBAAoB;AACxB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;AACpB","sourcesContent":[".row .col-4 {\r\n    text-align: center;\r\n}\r\n\r\n/* .credit-card-div span {\r\n    padding-top: 10px;\r\n} */\r\n\r\n.credit-card-div img {\r\n    padding-top: 30px;\r\n}\r\n\r\n/* .credit-card-div .small-font {\r\n    font-size: 15px;\r\n    line-height: 30px;\r\n} */\r\n\r\n.credit-card-div .pad-adjust {\r\n    padding-top: 10px;\r\n}\r\n\r\n.card-last-four {\r\n    font-size: 18px;\r\n    display: block;\r\n    letter-spacing: 2px;\r\n    padding-bottom: 20px;\r\n}\r\n\r\nlabel{\r\n    font-weight: 500;\r\n    padding-top: 4px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
