import { Component, Input, Output, EventEmitter } from "@angular/core";
import { SlickUtilsService } from "../utils/slick-utils.service";

@Component({
	selector: "slick-pick-list",
	templateUrl: "slick-pick-list.component.html"
})
export class SlickPickListComponent {
	@Input() leftItems: any[];
	@Output() leftItemsChange: EventEmitter<any[]> = new EventEmitter();
	@Input() leftTitle: string;
	@Input() rightItems: any[];
	@Output() rightItemsChange: EventEmitter<any[]> = new EventEmitter();
	@Input() rightTitle: string;
	@Input() sortOrderFieldName: string;
	@Input() displayFieldName: string;

	uuid: string;
	leftAnySelected = false;
	rightAnySelected = false;
	leftSelectedItems: any[] = [];
	rightSelectedItems: any[] = [];
	
	constructor() {
		this.uuid = SlickUtilsService.newGuid();
	}

	onLeftSelectedItemsChange(selectedItems: any[]) {
		this.leftSelectedItems = selectedItems || [];
		this.leftAnySelected = (this.leftSelectedItems.length > 0);			
		this.rightAnySelected =  (this.rightSelectedItems.length > 0);
	}

	onRightSelectedItemsChange(selectedItems: any[]) {
		this.rightSelectedItems = selectedItems || [];
		this.leftAnySelected = (this.leftSelectedItems.length > 0);
		this.rightAnySelected = (this.rightSelectedItems.length > 0);
	}

	moveLeft() {
		let rightSelectedUuids = this.rightSelectedItems.map(item => item.uuid);
		rightSelectedUuids.forEach(uuid => {
			let itemIndex = this.rightItems.findIndex(i => i.uuid === uuid);
			let item = this.rightItems.splice(itemIndex, 1)[0];			

			this.leftItems.push(item);
		});
		// Angular ngOnChange only works on reference detection.  This will create a new array with a new ref
		this.leftItems = this.leftItems.slice();
		this.rightItems = this.rightItems.slice();

		this.rightSelectedItems = [];

		if (this.leftItemsChange)
			this.leftItemsChange.emit(this.leftItems);

		if (this.rightItemsChange)
			this.rightItemsChange.emit(this.rightItems);

		this.leftAnySelected = false;
		this.rightAnySelected = false;
	}

	moveRight() {
		let leftSelectedUuids = this.leftSelectedItems.map(item => item.uuid);
		leftSelectedUuids.forEach(uuid => {
			let itemIndex = this.leftItems.findIndex(i => i.uuid === uuid);
			let item = this.leftItems.splice(itemIndex, 1)[0];

			this.rightItems.push(item);
		});
		// Angular ngOnChange only works on reference detection.  This will create a new array with a new ref
		this.rightItems = this.rightItems.slice();
		this.leftItems = this.leftItems.slice();

		this.leftSelectedItems = [];

		if (this.leftItemsChange)
			this.leftItemsChange.emit(this.leftItems);

		if (this.rightItemsChange)
			this.rightItemsChange.emit(this.rightItems);

		this.leftAnySelected = false;
		this.rightAnySelected = false;
	}
}