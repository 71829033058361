import { IMaintenanceReadingItemModel } from "../lookups/maintenance-reading-item.model";

export interface IMaintenanceReadingValueModel {
	maintenanceReadingId: number;
	maintenanceReadingItemId: number;
	itemName: string;
	itemValue: string;
}

export class MaintenanceReadingValueModel implements IMaintenanceReadingValueModel {
	maintenanceReadingId: number;
	maintenanceReadingItemId: number;
	itemName: string;
	itemValue: string;
}