
export interface IEmailModel{
    id: number;
    companyId: number;
    locationId?: number;
    lathamOrderId?: number;
    invoiceId?: number;
    jobId?: number;
    customerId?: number;
    sentTo: string;
    sendFrom: string;
    sentDate: Date;
    subject: string;
    body: string;
    errorMessage: string;
    uuid: string;
}
export class EmailModel  implements IEmailModel {
    id: number;
    companyId: number;
    locationId?: number;
    lathamOrderId?: number;
    invoiceId?: number;
    jobId?: number;
    customerId?: number;
    sentTo: string;
    sendFrom: string;
    sentDate: Date;
    subject: string;
    body: string;
    errorMessage: string;
    uuid: string;
}