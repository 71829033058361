import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from "@angular/core";

@Component({
	selector: "slick-card",
	templateUrl: "slick-card.component.html"
})
export class SlickCardComponent implements OnChanges {
	@Input() header;
	@Input() clickable: boolean = true;
	@Input() selected: boolean = false;
	@Input() cardKey: string = '';
	@Output() onSelect: EventEmitter<string> = new EventEmitter();

	ngOnChanges(changes: SimpleChanges) {
		if (changes.clickable) 
			this.clickable = (this.clickable === null || this.clickable === undefined) ? true : this.clickable.toString().toLowerCase() === "true";

		if (changes.selected) {
			if (this.clickable === true)
				this.selected = (this.selected === null || this.selected === undefined) ? false : this.selected.toString().toLowerCase() === "true";
			else
				this.selected = false;
		}
	}

	onClick() {
		if (this.clickable === true && this.onSelect)
			this.onSelect.emit(this.cardKey);
	}
}