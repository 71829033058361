// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.state-btn {
  width: 25px;
  height: 25px;
  padding: 0px;
  color: #343a40;
  border-color: #e9ecef;
  background-color: #e9ecef;
  font-size: 10px;
}

.condensed-button {
  width: 34px;
  padding-left: 0;
  padding-right: 0;
}

.address-list-item {
  line-height: 1.1em;
  font-size: 14px;
  list-style: none;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
}

.address-list-item li.bigger {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 3px;
}

.arrow-button {
  display: flex;
  flex-direction: column;
}

h5 {
  margin-bottom: 0px;
  font-size: 20px;
}

.job-label {
  color: var(--bs-gray-600);
}

.resource-label {
  font-size: 12px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./app-desktop/src/app/jobs/job-components/job-edit/job-edit.component.scss"],"names":[],"mappings":"AACA;EACI,WAAA;EACA,YAAA;EACA,YAAA;EACA,cAAA;EACA,qBAAA;EACA,yBAAA;EACA,eAAA;AAAJ;;AAGA;EACI,WAAA;EACA,eAAA;EACA,gBAAA;AAAJ;;AAGA;EACI,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,mBAAA;EACA,iBAAA;AAAJ;;AAGA;EACI,iBAAA;EACA,eAAA;EACA,kBAAA;AAAJ;;AAGA;EACI,aAAA;EACA,sBAAA;AAAJ;;AAGA;EACI,kBAAA;EACA,eAAA;AAAJ;;AAEA;EACI,yBAAA;AACJ;;AACA;EACI,eAAA;EACA,gBAAA;EACA,aAAA;EACA,8BAAA;AAEJ","sourcesContent":["\r\n.state-btn {\r\n    width: 25px;\r\n    height: 25px;\r\n    padding: 0px;\r\n    color: #343a40;\r\n    border-color: #e9ecef;\r\n    background-color: #e9ecef;\r\n    font-size: 10px;\r\n}\r\n\r\n.condensed-button {\r\n    width: 34px;\r\n    padding-left: 0;\r\n    padding-right: 0;\r\n}\r\n\r\n.address-list-item {\r\n    line-height: 1.1em;\r\n    font-size: 14px;\r\n    list-style: none;\r\n    padding-top: 5px;\r\n    padding-bottom: 5px;\r\n    padding-left: 5px;\r\n}\r\n\r\n.address-list-item li.bigger {\r\n    font-weight: bold;\r\n    font-size: 18px;\r\n    margin-bottom: 3px;\r\n}\r\n\r\n.arrow-button {\r\n    display: flex;\r\n    flex-direction: column;\r\n}\r\n\r\nh5{\r\n    margin-bottom: 0px;\r\n    font-size: 20px;\r\n}\r\n.job-label{\r\n    color: var(--bs-gray-600);\r\n}\r\n.resource-label {\r\n    font-size: 12px;\r\n    font-weight: 400;\r\n    display: flex;\r\n    justify-content: space-between;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
