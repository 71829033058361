import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';
import { SlickStarRatingComponent } from "./slick-star-rating.component";
import { SlickCalendarModule } from "../slick-calendar/slick-calendar.module";

@NgModule({
	imports: [CommonModule, FormsModule, SlickCalendarModule],
	declarations: [SlickStarRatingComponent],
	exports: [SlickStarRatingComponent, SlickCalendarModule]
})
export class SlickStarRatingModule { }

export { SlickStarRatingComponent }