import { IInvoiceModel } from "./invoice.model";

export interface IBatchInvoiceModel {
    mergedInvoiceFileName: string;
    mergedInvoiceFileUrl: string;
    invoiceModels: IInvoiceModel[];
    companyId: number;
    ipAddress: string;
    customerEmail: string;
    isInvoice: boolean;
}
	
export class BatchInvoiceModel implements IBatchInvoiceModel {
    mergedInvoiceFileName: string;
    mergedInvoiceFileUrl: string;
    invoiceModels: IInvoiceModel[];
    companyId: number;
    ipAddress: string;
    customerEmail: string;
    isInvoice: boolean;
    
}

	